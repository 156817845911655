import React from "react";
import { useRouter } from "next/router";

import styles from "./NewSearch.module.css";
import { ApiBaseUrl } from "../../api/apiClientBase";
import cn from "classnames";

interface NewSearchProps {
  logo?: number | null;
  title?: string;
  subtitle?: string;
  subtitleColor?: "black" | "white";
  subtitleShadow?: boolean;
  children: React.ReactNode;
  className?: string;
  background?: number | null;
  callback?: boolean;
  backgroundShadow?: string;
  breadcrumbs?: {
    url: string;
    title: string;
  }[];
}

const NewSearch = ({
  logo,
  title,
  subtitle,
  children,
  className,
  background,
  breadcrumbs,
  callback,
  backgroundShadow = "0%",
  subtitleColor = "black",
  subtitleShadow = false,
}: NewSearchProps) => {
  const router = useRouter();
  const isIzuchenieJazykaPage = router && router.asPath.includes('/izuchenie-jazyka');

  return (
    <div
      className={styles.NewSearch + ` ${breadcrumbs ? styles.NewSearchPadding__breadcrumbs : styles.NewSearchPadding}`}
      style={{
        backgroundImage: background ? `url(${ApiBaseUrl}/api/media/${background})` : undefined,
        backgroundColor: `rgba(0,0,0,${backgroundShadow})`,
      }}
    >
      <div
        className={`flex flex-col justify-center lg:items-start max-w-screen-xl w-full my-0 mx-auto ${callback ? "lg:flex-row lg:items-center" : ""}`}
      >
        <div className={cn("flex flex-col items-center pb-64 lg:pb-0 lg:mb-16 md:items-start md:px-14", styles.NewSearch__container)}>
          <h1 className={styles.heading}>{title}</h1>
          <h2 className={cn(subtitleColor === "white" ? styles.white_title : "", subtitleShadow ? styles.shadow_title : "")}>
            {logo && <img src={`${ApiBaseUrl}/api/media/${logo}`} alt="" />}
            {subtitle}
          </h2>
        </div>
        {!isIzuchenieJazykaPage && (
          <>
            <div className={`${className} hidden ${callback ? "flex flex-col lg:ml-auto" : styles.NewSearchBlock} lg:flex px-14`}>
              {children}
            </div>
            <div className={`${className} flex flex-col py-10 px-6 md:px-14 h-full lg:hidden ${styles.NewSearchBlock}`}>
              {children}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

// <br />
{
  /*<pre>{JSON.stringify(props)}</pre>*/
}

export default NewSearch;
