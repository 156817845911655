import React, {useRef} from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./PaymentForServicesBlock.module.css"
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import cn from "classnames";
import {CurvedText} from "./CurvedText";
import {MiniCurvedText} from "./MiniCurvedText";
import {StretchedMiniCurvedText} from "./StretchedMiniCurvedText";

export interface PaymentForServicesBlockElement {
    title: string,
    text: string,
    secondText: string,
    bottomIcon: null | number,
    bottomTitle: string,
    bottomText: string,
    curveText: string
}

export const PaymentForServicesBlock = (props: PaymentForServicesBlockElement) => {

    return (
        <div className={cn(styles.paymentForServices, "flex justify-center")}>
            <div className={"h-full w-full max-w-screen-xl flex relative"}>
                <div className={cn(styles.paymentForServices__curve, "hidden lg:flex")}>
                    <CurvedText text={`${props.curveText}`} />
                </div>
                <div className={styles.paymentForServices__textContainer}>
                    <div className={styles.paymentForServices__information}>
                        <div className="flex flex-col gap-5">
                            <div className={styles.paymentForServices__title}>{props.title}</div>
                            <div className="flex flex-col gap-2">
                                <div className={styles.paymentForServices__text}>{props.text}</div>
                                <div className={styles.paymentForServices__text}>{props.secondText}</div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <img src={`${ApiBaseUrl}/api/media/${props.bottomIcon}`} className={styles.paymentForServices__icon} alt=""/>
                            <div className={styles.paymentForServices__BottomTitle}>{props.bottomTitle}</div>
                            <div className={styles.paymentForServices__text}>{props.bottomText}</div>
                        </div>
                    </div>
                </div>
                <div className={cn(styles.paymentForServices__miniCurve, "hidden md:flex lg:hidden")}>
                    <StretchedMiniCurvedText text={`${props.curveText}`} />
                </div>
            </div>
        </div>
    );
};

export const PaymentForServicesBlockInfo: TypedBlockTypeInfo<PaymentForServicesBlockElement> = {
    id: "PaymentForServicesBlock",
    name: "PaymentForServicesBlock",
    preview: preview,
    renderer: PaymentForServicesBlock,
    initialData: {
        title: "string",
        text: "string",
        secondText: "string",
        bottomIcon: null,
        bottomTitle: "string",
        bottomText: "string",
        curveText: "string"
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "secondText",
                type: "String",
                name: "Second Text",
            },
            {
                id: "bottomIcon",
                type: "Custom",
                customType: "Image",
                name: "Bottom Icon"
            },
            {
                id: "bottomTitle",
                type: "String",
                name: "Bottom Title",
            },
            {
                id: "bottomText",
                type: "String",
                name: "Bottom Text",
            },
            {
                id: "curveText",
                type: "String",
                name: "Curve Text",
            },
        ],
    },
};
