import React, { FC } from "react";
import { useObserver } from "mobx-react";
import { AdminInputBox } from "src/components/common/AdminInputBox";
import { GlobalSettingsPageStore } from "../../../../stores/pages/adminStores/globalSettings/globalSettingsPageStore";

export const HousingModuleSettings: FC<{ s: GlobalSettingsPageStore }> = ({ s }) => {
    return useObserver(() => (
        <div>
            <AdminInputBox
                value={s.buttonCompare}
                label="Button Compare"
                onChange={(e) => (s.buttonCompare = e.target.value)}
            />
            <AdminInputBox
                value={s.buttonTraitsMoreDetails}
                label="Button Traits More Details"
                onChange={(e) => (s.buttonTraitsMoreDetails = e.target.value)}
            />
            <AdminInputBox
                value={s.buttonTraitsHideDetails}
                label="Button Traits Hide Details"
                onChange={(e) => (s.buttonTraitsHideDetails = e.target.value)}
            />
            <AdminInputBox
                value={s.curveText}
                label="Curve Text"
                onChange={(e) => (s.curveText = e.target.value)}
            />
            <AdminInputBox
                value={s.titleAccommodation}
                label="Title Accommodation"
                onChange={(e) => (s.titleAccommodation = e.target.value)}
            />
            <AdminInputBox
                value={s.buttonAccommodation}
                label="Button Accommodation"
                onChange={(e) => (s.buttonAccommodation = e.target.value)}
            />
            <AdminInputBox
                value={s.buttonAccommodationMoreDetails}
                label="Button Accommodation More Details"
                onChange={(e) => (s.buttonAccommodationMoreDetails = e.target.value)}
            />
            <AdminInputBox
                value={s.titleLocation}
                label="Title Location"
                onChange={(e) => (s.titleLocation = e.target.value)}
            />
            <AdminInputBox
                value={s.descriptionQuartierLatin}
                label="Description Quartier Latin"
                onChange={(e) => (s.descriptionQuartierLatin = e.target.value)}
            />
        </div>
    ));
};
