import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./SolidTeamBlock.module.css";
import preview from "./preview.png";

export interface SolidTeamBlockElement {
  title: string;
  text: string;
  highlightedText: string;
  subtext: string;
  buttonText: string;
  buttonLink: string;
  videoLink: string;
}

export const SolidTeamBlock = ({
  title,
  text,
  subtext,
  highlightedText,
  buttonText,
  buttonLink,
  videoLink,
}: SolidTeamBlockElement) => {
  const textBefore = text.slice(0, text.indexOf(highlightedText));
  const textAfter =
    textBefore.length !== text.length ? text.slice(text.indexOf(highlightedText) + highlightedText.length) : "";

  return (
    <div className="py-12">
      <div className={styles.solidTeamBlock__title + " text-center mx-auto md:mb-6 mb-3"}>{title}</div>
      <div className={styles.solidTeamBlock__text + " text-center"}>
        {textBefore}
        {highlightedText && <span>{highlightedText}</span>}
        {textAfter}
      </div>
      <div className={styles.solidTeamBlock__subtext + " text-center md:mb-6 mb-3"}>{subtext}</div>
      <div className="flex justify-center md:mt-6">
        <a type={"button"} className={styles.solidTeamBlock__button} href={buttonLink}>
          <span>{buttonText}</span>
        </a>
      </div>
      {videoLink && (
        <div className={styles.solidTeamBlock__video}>
          <div style={{ minWidth: "100%" }}>
            <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
              <iframe
                src={videoLink}
                allow="autoplay; encrypted-media; screen-wake-lock"
                allowFullScreen
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const SolidTeamBlockInfo: TypedBlockTypeInfo<SolidTeamBlockElement> = {
  id: "SolidTeamBlock",
  name: "SolidTeamBlock",
  preview: preview,
  renderer: SolidTeamBlock,
  initialData: {
    title: "string",
    text: "this is string, not a number",
    highlightedText: "string",
    subtext: "string",
    buttonText: "string",
    buttonLink: "",
    videoLink: "",
  },
  definition: {
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "text",
        type: "String",
        name: "Text",
      },
      {
        id: "highlightedText",
        type: "String",
        name: "Highlighted Text",
      },
      {
        id: "subtext",
        type: "String",
        name: "Subtext",
      },
      {
        id: "buttonText",
        type: "String",
        name: "Button Text",
      },
      {
        id: "link",
        type: "String",
        name: "Button Link",
      },
      {
        id: "videoLink",
        type: "String",
        name: "Video Link",
      },
    ],
  },
};
