import React, { useContext, useEffect } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./AdditionalInformationBlock.module.css";
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";
import { ComponentHostContext } from "../index";

export interface AdditionalInformationBlockElement {
    title: string;
    subtitle: string;
    elements: { title: string; text: string }[];
    textButton: string;
    bottomItems: {name: string}[]
}

const splitTitle = (title: string) => {
    const parts = title.split(' ');
    if (parts.length > 1) {
        return (
            <span>
                {parts[0]} <span className={styles.additionalInformation__elementHeader}>{parts.slice(1).join(' ')}</span>
            </span>
        );
    }
    return <span>{title}</span>;
};

export const AdditionalInformationBlock = (props: AdditionalInformationBlockElement) => {
    useEffect(() => {
        const headerElement = document.getElementById("header_nav");
        const updateHeaderColor = () => {
            if (headerElement) {
                if (window.innerWidth <= 1024) {
                    headerElement.classList.add(styles.blueBackground);
                } else {
                    headerElement.classList.remove(styles.blueBackground);
                }
            }
        };
        updateHeaderColor();
        window.addEventListener("resize", updateHeaderColor);
        return () => {
            window.removeEventListener("resize", updateHeaderColor);
        };
    }, []);
    const cl = useContext(ComponentHostContext);
    return (
        <div className={cn(styles.additionalInformation__body)}>
            <div className="flex justify-between flex-col lg:flex-row lg:items-start max-w-screen-xl h-full w-full my-0 mx-auto p-9 pt-20 lg:py-20">
                <div className="flex flex-col md:h-full justify-start gap-10 md:gap-5 md:justify-between items-center lg:items-start">
                    <div className={cn(styles.additionalInformation__title)}>
                        {props.title}
                    </div>
                    <div className={cn(styles.additionalInformation__subtitle, "w-3/5 md:w-4/5 lg:w-4/6")}>
                        {props.subtitle}
                    </div>
                    <button className={cn(styles.additionalInformation__button)} onClick={() =>cl?.showContactUsFormServicePink()}>
                        {props.textButton}
                    </button>

                </div>
                <div className={cn(styles.additionalInformation__elements, "hidden md:flex lg:flex-col justify-between lg:h-full")}>
                    {props.elements.map((el, index) => (
                        <div key={index} className={cn(styles.additionalInformation__element)}>
                            <div className={styles.additionalInformation__elementTitle}>
                                {splitTitle(el.title)}
                            </div>
                            <div className={styles.additionalInformation__elementText}>
                                {el.text}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={cn(styles.sliderContainer, "flex my-8 text-center md:hidden")}>
                    <Slider>
                        {props.elements.map((el, index) => (
                            <div key={index} className={styles.slider__element}>
                                <div className={styles.additionalInformation__elementTitle}>
                                    {splitTitle(el.title)}
                                </div>
                                <div className={styles.additionalInformation__elementText}>
                                    {el.text}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            <div className={cn("w-full overflow-x-auto", styles.bottomContainer)}>
                <div className={cn("flex justify-center gap-2 flex-nowrap px-4", styles.scrollContainer)} style={{ minWidth: "max-content" }}>
                    {props.bottomItems.map((item, index) => (
                        <div key={index} className={styles.bottomItem}>
                            {item.name.toUpperCase()}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export const AdditionalInformationBlockInfo: TypedBlockTypeInfo<AdditionalInformationBlockElement> = {
    id: "AdditionalInformationBlock",
    name: "AdditionalInformationBlock",
    preview: preview,
    renderer: AdditionalInformationBlock,
    initialData: {
        title: "string",
        subtitle: "string",
        elements: [{
            title: "string",
            text: "string"
        }],
        textButton: "string",
        bottomItems: [{
            name: "string"
        }]
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "title",
                        type: "String",
                        name: "Title"
                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text"
                    },
                ],
            },
            bottomItem: {
                fields: [
                    {
                        id: "name",
                        type: "String",
                        name: "Name"
                    }
                ]
            }
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "subtitle",
                type: "String",
                name: "Subtitle",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "textButton",
                type: "String",
                name: "Text button",
            },
            {
                id: "bottomItems",
                type: "List",
                listType: "bottomItem",
                name: "Bottom Items"
            },
        ]
    }
}
