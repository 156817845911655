import React, { useEffect, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./StatisticsBlock.module.css";
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";
import {ApiBaseUrl} from "../../api/apiClientBase";

export interface StatisticsBlockElement {
    header: string;
    elements: { title: string; text: string; icon: number | null }[];
}

const splitTitle = (title: string) => {
    const parts = title.split(' ');
    if (parts.length > 1) {
        return (
            <span>
                <span className={style.statisticsBlock__elementHeader}>{parts.slice(0, 1).join(' ')}</span> {parts[1]}
            </span>
        );
    }
    return <span>{title}</span>;
};

export const StatisticsBlock = (props: StatisticsBlockElement) => {
    const [isVisible, setIsVisible] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.7,
            }
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, []);

    const getFadeInClass = (delay: number) => ({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(20px)",
        transition: `opacity 1.5s ease-out ${delay}s, transform 1.5s ease-out ${delay}s`
    });
    return (
        <div ref={blockRef} className={cn(style.statisticsBlock__body)}>
            <div className={cn("flex flex-col justify-center lg:items-start max-w-screen-xl w-full my-0 mx-auto px-4 lg:px-14 relative", style.statisticsBlock__animation, { [style.statisticsBlockVisible]: isVisible })}>
                <div className="flex items-center lg:items-start flex-col">
                    <div className={cn("pt-14", style.statisticsBlock__header)} style={{...getFadeInClass(0)}}>
                        <h1>{props.header}</h1>
                    </div>
                </div>
                <div className={cn(
                    style.statisticsBlock__elements,
                    "hidden lg:grid text-left"
                )}>
                    {props.elements.map((el, index) => (
                        <div key={index} className={cn(style.statisticsBlock__element, "flex flex-col gap-2")}>
                            <img src={`${ApiBaseUrl}/api/media/${el.icon}`} alt="" className={cn(style.statisticsBlock__elementIcon)}/>
                            <div className={"flex flex-col gap-3"}>
                                <div className={style.statisticsBlock__elementTitle} style={{...getFadeInClass(0.2)}}>
                                    {splitTitle(el.title)}
                                </div>
                                <div className={style.statisticsBlock__elementText} style={{...getFadeInClass(0.3)}}>
                                    {el.text}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={cn("flex my-8 text-center lg:hidden")}>
                    <Slider>
                        {props.elements.map((el, index) => (
                            <div key={index} className={cn(style.slider__element, "flex flex-col gap-2")}>
                                <img src={`${ApiBaseUrl}/api/media/${el.icon}`} alt="" className={cn(style.statisticsBlock__elementIcon)}/>
                                <div className={style.statisticsBlock__elementTitle}>
                                    {splitTitle(el.title)}
                                </div>
                                <div className={style.statisticsBlock__elementText} style={{...getFadeInClass(0.3)}}>
                                    {el.text}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export const StatisticsBlockInfo: TypedBlockTypeInfo<StatisticsBlockElement> = {
    id: "statisticsBlock",
    name: "StatisticsBlock",
    preview: preview,
    renderer: StatisticsBlock,
    initialData: {
        header: "Header",
        elements: [
            {
                title: "string",
                text: "string",
                icon: null
            },
        ],
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "title",
                        type: "String",
                        name: "Title"
                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text"
                    },
                    {
                        id: "icon",
                        type: "Custom",
                        customType: "Image",
                        name: "Icon"
                    },
                ],
            },
        },
        fields: [
            {
                id: "header",
                type: "String",
                name: "Header",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
        ]
    }
}
