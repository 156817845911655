import React, { useContext, useEffect } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./HelpIntroBlock.module.css";
import cn from "classnames";
import { ComponentHostContext } from "../index";

export interface HelpIntroBlockElement {
    title: string;
    subtitle: string;
    miniNoteText: string;
    price: string;
    textUnderPrice: string;
    priceDescription: string;
    whiteButtonText: string;
    whiteButtonLink: string;
    redButtonText: string;
}

export const HelpIntroBlock = (props: HelpIntroBlockElement) => {
    useEffect(() => {
        const headerElement = document.getElementById("header_nav");
        const updateHeaderColor = () => {
            if (headerElement) {
                if (window.innerWidth <= 1024) {
                    headerElement.classList.add(styles.blueBackground);
                } else {
                    headerElement.classList.remove(styles.blueBackground);
                }
            }
        };
        updateHeaderColor();
        window.addEventListener("resize", updateHeaderColor);
        return () => {
            window.removeEventListener("resize", updateHeaderColor);
        };
    }, []);
    const cl = useContext(ComponentHostContext);
    return (
        <div className={cn(styles.helpIntroBlock__body)}>
            <div className="flex justify-between flex-col gap-10 lg:gap-20 lg:items-start max-w-screen-xl h-full w-full my-0 mx-auto p-6 pt-20 lg:py-20">
                <div className="flex flex-col justify-start gap-10 items-center lg:items-start">
                    <div className={cn(styles.helpIntroBlock__title, "relative")}>
                        <div className={cn("absolute", styles.helpIntroBlock__miniNote)}>{props.miniNoteText.toUpperCase()}</div>
                        <div className={cn("w-full")}>
                            {props.title}
                        </div>
                    </div>
                    <div className={cn(styles.helpIntroBlock__text, "w-full md:w-4/5 lg:w-4/6")}>
                        {props.subtitle}
                    </div>
                </div>
                <div className={"w-full"}>
                    <div className={cn("hidden md:flex lg:hidden gap-5 justify-center mb-10")}>
                        <button className={cn(styles.helpIntroBlock__button)}
                                style={{backgroundColor: "white", color: "#373737"}}>
                            {props.whiteButtonText}
                        </button>
                        <button className={cn(styles.helpIntroBlock__button)}>{props.redButtonText}</button>
                    </div>
                    <div className={cn(styles.helpIntroBlock__price)}>
                        {props.price}
                    </div>
                    <div className={cn("mb-6", styles.helpIntroBlock__text)}>
                        {props.textUnderPrice}
                    </div>
                    <div className={"w-full flex justify-between items-center flex-col lg:flex-row"}>
                        <div className={cn("w-3/4 md:w-5/12 mb-20 md:mb-10 lg:mb-0", styles.helpIntroBlock__text)}>
                            {props.priceDescription}
                        </div>
                        <div className={cn("flex flex-col lg:flex-row md:hidden gap-3 lg:flex lg:gap-7 w-full lg:w-auto mb-10 lg:mb-0 text-center")}>
                            <a className={cn(styles.helpIntroBlock__button)}
                                    style={{backgroundColor: "white", color: "#373737"}}>
                                {props.whiteButtonText}
                            </a>
                            <button onClick={() =>cl?.showContactUsFormServicePink()} className={cn(styles.helpIntroBlock__button)}>{props.redButtonText}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export const HelpIntroBlockInfo: TypedBlockTypeInfo<HelpIntroBlockElement> = {
    id: "HelpIntroBlock",
    name: "HelpIntroBlock",
    preview: preview,
    renderer: HelpIntroBlock,
    initialData: {
        title: "string",
        subtitle: "string",
        miniNoteText: "string",
        price: "string",
        textUnderPrice: "string",
        priceDescription: "string",
        whiteButtonText: "string",
        whiteButtonLink: "#",
        redButtonText: "string",
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "subtitle",
                type: "String",
                name: "Subtitle",
            },
            {
                id: "miniNoteText",
                type: "String",
                name: "Mini Note Text",
            },
            {
                id: "price",
                type: "String",
                name: "Price",
            },
            {
                id: "textUnderPrice",
                type: "String",
                name: "Text Under Price",
            },
            {
                id: "priceDescription",
                type: "String",
                name: "Price Description",
            },
            {
                id: "whiteButtonText",
                type: "String",
                name: "White Button Text",
            },
            {
                id: "whiteButtonLink",
                type: "String",
                name: "White Button Link",
            },
            {
                id: "redButtonText",
                type: "String",
                name: "Red Button Text",
            },
        ]
    }
}
