import { RequestTracking } from "src/utils/Loadable";
import { AdminApi } from "src/clients/adminApiClient";
import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import {
  GlobalSettingsDto,
  LinkDto,
  SocialLinkDto,
  LinkListDto,
  ContactLinkDto,
  PersonalCabinetDto,
  ContactWayItemsDto,
} from "src/interfaces/GlobalSettingsDto";

export class GlobalSettingsPageStore extends RequestTracking {
  @observable headerTopLink: LinkDto[] = [];
  @observable headerSocialLink: SocialLinkDto[] = [];
  @observable headerBottomLink: LinkDto[] = [];

  @observable footerTopLink: LinkDto[] = [];
  @observable footerLinkList: LinkListDto[] = [];
  @observable footerContactLinkList: ContactLinkDto[] = [];
  @observable footerSocialLink: SocialLinkDto[] = [];

  @observable requestFormTitle: string = "";
  @observable requestFormLeftTitle: string = "";
  @observable requestFormRightTitle: string = "";
  @observable requestFormPostText: string = "";

  @observable catalogUniversityTitle: string = "";
  @observable catalogUniversityDescription: string = "";
  @observable catalogUniversitySearch: string = "";
  @observable catalogHousingTitle: string = "";
  @observable catalogHousingDescription: string = "";
  @observable catalogHousingSearch: string = "";
  @observable catalogCourseTitle: string = "";
  @observable catalogCourseDescription: string = "";
  @observable catalogCourseSearch: string = "";
  @observable catalogSearchButton: string = "";

  @observable knowledgeBaseTitle: string = "";
  @observable knowledgeBaseDescription: string = "";
  @observable knowledgeBaseSearchInput: string = "";
  @observable knowledgeBaseSearchButton: string = "";
  @observable knowledgeBaseDescriptionTestCard: string = "";
  @observable knowledgeBaseCurveTestCard: string = "";
  @observable knowledgeBaseButtonTestCard: string = "";
  @observable knowledgeBaseLinkTestCard: string = "";

  @observable buttonCompare: string = "";
  @observable buttonTraitsMoreDetails: string = "";
  @observable buttonTraitsHideDetails: string = "";
  @observable curveText: string = "";
  @observable titleAccommodation: string = "";
  @observable buttonAccommodation: string = "";
  @observable buttonAccommodationMoreDetails: string = "";
  @observable titleLocation: string = "";
  @observable descriptionQuartierLatin: string = "";

  @observable reviewsTitleTop: string = "";
  @observable reviewsDescriptionTop: string = "";
  @observable reviewsButtonTop: string = "";
  @observable reviewsBredcrumb: LinkDto[] = [];
  @observable reviewsRequestTitle: string = "";
  @observable reviewsRequestContactWayPlaceholder: string = "";
  @observable reviewsRequestContactWayItems: ContactWayItemsDto[] = [];
  @observable reviewsRequestPhonePlaceholder: string = "";
  @observable reviewsRequestEmailPlaceholder: string = "";
  @observable reviewsRequestNamePlaceholder: string = "";
  @observable reviewsRequestButtonText: string = "";
  @observable reviewsRequestButtonDescription: string = "";
  @observable reviewsRequestLinkedTextInButtonDescription: string = "";
  @observable reviewsRequestCurveText: string = "";
  @observable reviewsRequestCustomMargin: boolean = false;
  @observable reviewsRequestTopMargin: string = "";
  @observable reviewsRequestBottomMargin: string = "";

  @observable testModalDescription: string = "";
  @observable testModalPlaceholder: string = "";
  @observable testModalButton: string = "";

  @observable alert: boolean = false;
  @observable buttonDisabled: boolean = false;
  @observable lang: string = "";
  @observable personalCabinet: PersonalCabinetDto = {};

  constructor(public rootStore: RootStore) {
    super();
  }

  @action resetState() {
    this.headerTopLink = [];
    this.headerSocialLink = [];
    this.headerBottomLink = [];
    this.footerTopLink = [];
    this.footerLinkList = [];
    this.footerContactLinkList = [];
    this.footerSocialLink = [];
    this.requestFormTitle = "";
    this.requestFormLeftTitle = "";
    this.requestFormRightTitle = "";
    this.requestFormPostText = "";
    this.catalogUniversityTitle = "";
    this.catalogUniversityDescription = "";
    this.catalogUniversitySearch = "";
    this.catalogHousingTitle = "";
    this.catalogHousingDescription = "";
    this.catalogHousingSearch = "";
    this.catalogCourseTitle = "";
    this.catalogCourseDescription = "";
    this.catalogCourseSearch = "";
    this.catalogSearchButton = "";
    this.knowledgeBaseTitle = "";
    this.knowledgeBaseDescription = "";
    this.knowledgeBaseSearchInput = "";
    this.knowledgeBaseSearchButton = "";
    this.knowledgeBaseDescriptionTestCard = "";
    this.knowledgeBaseCurveTestCard = "";
    this.knowledgeBaseButtonTestCard = "";
    this.knowledgeBaseLinkTestCard = "";
    this.reviewsTitleTop = "";
    this.reviewsDescriptionTop = "";
    this.reviewsButtonTop = "";
    this.reviewsBredcrumb = [];
    this.reviewsRequestTitle = "";
    this.reviewsRequestContactWayPlaceholder = "";
    this.reviewsRequestContactWayItems = [];
    this.reviewsRequestPhonePlaceholder = "";
    this.reviewsRequestEmailPlaceholder = "";
    this.reviewsRequestNamePlaceholder = "";
    this.reviewsRequestButtonText = "";
    this.reviewsRequestButtonDescription = "";
    this.reviewsRequestLinkedTextInButtonDescription = "";
    this.reviewsRequestCurveText = "";
    this.reviewsRequestCustomMargin = false;
    this.reviewsRequestBottomMargin = "";
    this.reviewsRequestTopMargin = "";
    this.buttonCompare = "";
    this.buttonTraitsMoreDetails = "";
    this.buttonTraitsHideDetails = "";
    this.curveText = "";
    this.titleAccommodation = "";
    this.buttonAccommodation = "";
    this.buttonAccommodationMoreDetails = "";
    this.titleLocation = "";
    this.descriptionQuartierLatin = "";
    this.testModalDescription = "";
    this.testModalPlaceholder = "";
    this.testModalButton = "";
  }

  @action async load(lang: string) {
    this.resetState();
    this.lang = lang;
    try {
      const req: GlobalSettingsDto = await this.track(() => AdminApi.getGlobalSettings(lang));
      this.headerTopLink = req.header.headerTopLink ?? [];
      this.headerSocialLink = req.header.headerSocialLink ?? [];
      this.headerBottomLink = req.header.headerBottomLink ?? [];
      this.footerTopLink = req.footer.footerTopLink ?? [];
      this.footerLinkList = req.footer.footerLinkList ?? [];
      this.footerContactLinkList = req.footer.footerContactLinkList ?? [];
      this.footerSocialLink = req.footer.footerSocialLink ?? [];
      this.requestFormTitle = req.requestForm.requestFormTitle;
      this.requestFormLeftTitle = req.requestForm.requestFormLeftTitle;
      this.requestFormRightTitle = req.requestForm.requestFormRightTitle;
      this.requestFormPostText = req.requestForm.requestFormPostText;
      this.catalogUniversityTitle = req.catalog.catalogUniversityTitle;
      this.catalogUniversityDescription = req.catalog.catalogUniversityDescription;
      this.catalogUniversitySearch = req.catalog.catalogUniversitySearch;
      this.catalogHousingTitle = req.catalog.catalogHousingTitle;
      this.catalogHousingDescription = req.catalog.catalogHousingDescription;
      this.catalogHousingSearch = req.catalog.catalogHousingSearch;
      this.catalogCourseTitle = req.catalog.catalogCourseTitle;
      this.catalogCourseDescription = req.catalog.catalogCourseDescription;
      this.catalogCourseSearch = req.catalog.catalogCourseSearch;
      this.catalogSearchButton = req.catalog.catalogSearchButton;
      this.knowledgeBaseTitle = req.knowledgeBase.knowledgeBaseTitle;
      this.knowledgeBaseDescription = req.knowledgeBase.knowledgeBaseDescription;
      this.knowledgeBaseSearchInput = req.knowledgeBase.knowledgeBaseSearchInput;
      this.knowledgeBaseSearchButton = req.knowledgeBase.knowledgeBaseSearchButton;
      this.knowledgeBaseDescriptionTestCard = req.knowledgeBase.knowledgeBaseDescriptionTestCard;
      this.knowledgeBaseCurveTestCard = req.knowledgeBase.knowledgeBaseCurveTestCard;
      this.knowledgeBaseButtonTestCard = req.knowledgeBase.knowledgeBaseButtonTestCard;
      this.knowledgeBaseLinkTestCard = req.knowledgeBase.knowledgeBaseLinkTestCard;   
      this.reviewsTitleTop = req.reviews.reviewsTitleTop;
      this.reviewsDescriptionTop = req.reviews.reviewsDescriptionTop;
      this.reviewsButtonTop = req.reviews.reviewsButtonTop;
      this.reviewsBredcrumb = req.reviews.reviewsBredcrumb ?? [];
      this.reviewsRequestTitle = req.reviews.reviewsRequestTitle;
      this.reviewsRequestContactWayPlaceholder = req.reviews.reviewsRequestContactWayPlaceholder;
      this.reviewsRequestContactWayItems = req.reviews.reviewsRequestContactWayItems ?? [];
      this.reviewsRequestPhonePlaceholder = req.reviews.reviewsRequestPhonePlaceholder;
      this.reviewsRequestEmailPlaceholder = req.reviews.reviewsRequestEmailPlaceholder;
      this.reviewsRequestNamePlaceholder = req.reviews.reviewsRequestNamePlaceholder;
      this.reviewsRequestButtonText = req.reviews.reviewsRequestButtonText;
      this.reviewsRequestButtonDescription = req.reviews.reviewsRequestButtonDescription;
      this.reviewsRequestLinkedTextInButtonDescription = req.reviews.reviewsRequestLinkedTextInButtonDescription;
      this.reviewsRequestCurveText = req.reviews.reviewsRequestCurveText;
      this.reviewsRequestCustomMargin = req.reviews.reviewsRequestCustomMargin;
      this.reviewsRequestTopMargin = req.reviews.reviewsRequestTopMargin;
      this.reviewsRequestBottomMargin = req.reviews.reviewsRequestBottomMargin;
      this.buttonCompare = req.housingModule.buttonCompare;
      this.buttonTraitsMoreDetails = req.housingModule.buttonTraitsMoreDetails;
      this.buttonTraitsHideDetails = req.housingModule.buttonTraitsHideDetails;
      this.curveText = req.housingModule.curveText;
      this.titleAccommodation = req.housingModule.titleAccommodation;
      this.buttonAccommodation = req.housingModule.buttonAccommodation;
      this.buttonAccommodationMoreDetails = req.housingModule.buttonAccommodationMoreDetails;
      this.titleLocation = req.housingModule.titleLocation;
      this.descriptionQuartierLatin = req.housingModule.descriptionQuartierLatin;
      this.testModalDescription = req.testModal.testModalDescription;
      this.testModalPlaceholder = req.testModal.testModalPlaceholder;
      this.testModalButton = req.testModal.testModalButton;
      this.personalCabinet = req.personalCabinet ?? {};
    } catch (e) {}
  }

  async save() {
    this.buttonDisabled = true;
    const data: GlobalSettingsDto = {
      header: {
        headerTopLink: this.headerTopLink,
        headerSocialLink: this.headerSocialLink,
        headerBottomLink: this.headerBottomLink,
      },
      requestForm: {
        requestFormLeftTitle: this.requestFormLeftTitle,
        requestFormRightTitle: this.requestFormRightTitle,
        requestFormTitle: this.requestFormTitle,
        requestFormPostText: this.requestFormPostText,
      },
      footer: {
        footerTopLink: this.footerTopLink,
        footerLinkList: this.footerLinkList,
        footerContactLinkList: this.footerContactLinkList,
        footerSocialLink: this.footerSocialLink,
      },
      catalog: {
        catalogUniversityTitle: this.catalogUniversityTitle,
        catalogUniversityDescription: this.catalogUniversityDescription,
        catalogUniversitySearch: this.catalogUniversitySearch,
        catalogHousingTitle: this.catalogHousingTitle,
        catalogHousingDescription: this.catalogHousingDescription,
        catalogHousingSearch: this.catalogHousingSearch,
        catalogCourseTitle: this.catalogCourseTitle,
        catalogCourseDescription: this.catalogCourseDescription,
        catalogCourseSearch: this.catalogCourseSearch,
        catalogSearchButton: this.catalogSearchButton
      },
      knowledgeBase: {
        knowledgeBaseTitle: this.knowledgeBaseTitle,
        knowledgeBaseDescription: this.knowledgeBaseDescription,
        knowledgeBaseSearchInput: this.knowledgeBaseSearchInput,
        knowledgeBaseSearchButton: this.knowledgeBaseSearchButton,
        knowledgeBaseDescriptionTestCard: this.knowledgeBaseDescriptionTestCard,
        knowledgeBaseCurveTestCard: this.knowledgeBaseCurveTestCard,
        knowledgeBaseButtonTestCard: this.knowledgeBaseButtonTestCard,
        knowledgeBaseLinkTestCard: this.knowledgeBaseLinkTestCard,
      },
      reviews:{
        reviewsTitleTop: this.reviewsTitleTop,
        reviewsDescriptionTop: this.reviewsDescriptionTop,
        reviewsButtonTop: this.reviewsButtonTop,
        reviewsBredcrumb: this.reviewsBredcrumb,
        reviewsRequestTitle: this.reviewsRequestTitle,
        reviewsRequestContactWayPlaceholder: this.reviewsRequestContactWayPlaceholder,
        reviewsRequestContactWayItems: this.reviewsRequestContactWayItems,
        reviewsRequestPhonePlaceholder: this.reviewsRequestPhonePlaceholder,
        reviewsRequestEmailPlaceholder: this.reviewsRequestEmailPlaceholder,
        reviewsRequestNamePlaceholder: this.reviewsRequestNamePlaceholder,
        reviewsRequestButtonText: this.reviewsRequestButtonText,
        reviewsRequestButtonDescription: this.reviewsRequestButtonDescription,
        reviewsRequestLinkedTextInButtonDescription: this.reviewsRequestLinkedTextInButtonDescription,
        reviewsRequestCurveText: this.reviewsRequestCurveText,
        reviewsRequestCustomMargin: this.reviewsRequestCustomMargin,
        reviewsRequestTopMargin: this.reviewsRequestTopMargin,
        reviewsRequestBottomMargin: this.reviewsRequestBottomMargin
      },
      housingModule: {
        buttonCompare: this.buttonCompare,
        buttonTraitsMoreDetails: this.buttonTraitsMoreDetails,
        buttonTraitsHideDetails: this.buttonTraitsHideDetails,
        curveText: this.curveText,
        titleAccommodation: this.titleAccommodation,
        buttonAccommodation: this.buttonAccommodation,
        buttonAccommodationMoreDetails: this.buttonAccommodationMoreDetails,
        titleLocation: this.titleLocation,
        descriptionQuartierLatin: this.descriptionQuartierLatin
      },
      testModal: {
        testModalDescription: this.testModalDescription,
        testModalPlaceholder: this.testModalPlaceholder,
        testModalButton: this.testModalButton,
      },
      personalCabinet: this.personalCabinet,
    };
    try {
      await this.track(() => AdminApi.putGlobalSettings(this.lang, data));
      this.alert = true;
      alert("saved");
    } catch (e) {
      alert(e);
    }

    this.buttonDisabled = false;
  }
}
