import React, {useContext, useEffect, useRef, useState} from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./LevelTestBlock.module.css";
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import {ComponentHostContext} from "../index";

export interface LevelTestBlockElement {
    title: string;
    text: string;
    backgroundImg: number | null;
    textButton: string;
    link?: string
}

export const LevelTestBlock = (props: LevelTestBlockElement) => {
    const cl = useContext(ComponentHostContext);
    const [isVisible, setIsVisible] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    console.log("IntersectionObserver entry:", entry);
                    if (entry.isIntersecting) {
                        console.log("Element is visible in viewport.");
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.5,
            }
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, []);

    return (
        <div className={styles.levelTestBlock} ref={blockRef}>
            <div className={"flex mx-auto max-w-screen-xl relative w-full h-full self-center"}>
                <div className={`flex flex-col lg:justify-center mx-auto lg:mx-0 lg:h-full gap-5 ${styles.levelTestBlock__info}`}>
                    <div className={styles.levelTestBlock__title}>{props.title}</div>
                    <div className={styles.levelTestBlock__text}>{props.text}</div>
                    {props.link ? (
                        <a type={"button"} className={styles.button} href={props.link}>
                            <span>{props.textButton}</span>
                        </a>
                    ) : (
                        <button type={"button"} className={styles.button} onClick={() => cl?.showContactUsForm()}>
                            <span>{props.textButton}</span>
                        </button>
                    )}
                </div>
                <img
                    src={`${ApiBaseUrl}/api/media/${props.backgroundImg}`}
                    alt=""
                    className={`${styles.levelTestBlock__image} ${isVisible ? styles.levelTestBlock__imageVisible : ""}`}
                />
            </div>
        </div>
    );
};

export const LevelTestBlockInfo: TypedBlockTypeInfo<LevelTestBlockElement> = {
    id: "LevelTestBlock",
    name: "LevelTestBlock",
    preview: preview,
    renderer: LevelTestBlock,
    initialData: {
        title: "string",
        text: "string",
        backgroundImg: null,
        textButton: "string",
        link: ""
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "backgroundImg",
                type: "Custom",
                customType: "Image",
                name: "Background Image",
            },
            {
                id: "textButton",
                type: "String",
                name: "Text Button",
            },
            {
                id: "link",
                type: "String",
                name: "Link",
            }
        ],
    },
};
