import React from "react";
import styles from "./KnowledgeBaseArticleFirstBlock.module.css";
import preview from "./preview.png";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";
import { PageTraitDto } from "web-app/src/interfaces/pagesDto";
import Timer from "../../assets/icons/timer.svg";
import Calendar from "../../assets/icons/calendar-2.svg";
import { type } from "os";

export interface KnowledgeBaseArticleFirstBlockElement {
    title: string;
    subtitle: string;
    img: number | null;
    smallBlock: boolean;
    date: string;
    time: string;
    timeDescription: string;
    tags: PageTraitDto[] | null;
    author: string;
    logoAuthor: number | null;
    topMargin?: string;
    bottomMargin?: string;
    customMargin?: boolean;
}

export const KnowledgeBaseArticleFirstBlock: React.FC<KnowledgeBaseArticleFirstBlockElement> = ({ title, subtitle, img, date, time, timeDescription, tags, topMargin, bottomMargin, customMargin }) => {
    const marginStyles = customMargin
    ? {
        marginTop: topMargin ? `${topMargin}px` : undefined,
        marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
    }
    : {};
    return (
        <div className={cn("my-12 flex flex-col items-center justify-between max-w-screen-xl w-full gap-5 mx-auto px-2 md:px-8")} style={marginStyles}> 
            <div className={cn("flex lg:flex-row flex-col-reverse w-full")}>
                <div className={cn("lg:mr-10 lg:w-9/12")}>
                    <h2 className={cn(styles.title)}>{title}</h2>
                    <span className={cn(styles.subtitle)}>{subtitle}</span>
                </div>
                <div className="lg:w-3/12 flex flex-row lg:flex-col gap-8 mb-4 lg:gap-0">
                    {date && (
                        <div className={cn("flex flex-row")}>
                            <img src={Calendar} width={16} height={16} alt="" />
                            <span className={cn(styles.date)}>{date}</span>
                        </div>
                    )}
                    {time &&
                        <div className={cn("flex flex-row")}>
                            <img src={Timer} width={16} height={16} alt="" />
                            <span className={cn(styles.time)}>{time}</span>
                            <span className={cn(styles.timeDescription)}>{timeDescription}</span>
                        </div>
                    }
                    <div className={cn("h-4/6 hidden lg:flex flex-row flex-wrap items-end content-end gap-2 ")}>
                        {tags &&
                            tags.map((tag, i) => (
                                <span className={cn(styles.tags)} key={tag.id + i}>
                                    {tag.name}
                                </span>
                            ))}
                    </div>
                </div>
            </div>
            <div className={cn("h-4/6 lg:hidden flex w-full flex-row flex-wrap items-end content-end gap-2 ")}>
                {tags &&
                    tags.map((tag, i) => (
                        <span className={cn(styles.tags)} key={tag.id + i}>
                            {tag.name}
                        </span>
                    ))}
            </div>
            <img
                className={"mt-5 lg:mt-10"}
                src={img ? `${ApiBaseUrl}/api/media/${img}` : "/default-image.png"}
                alt=""
            />
        </div>
    );
};

export const KnowledgeBaseArticleFirstBlockInfo = {
    id: "KnowledgeBaseArticleFirstBlock",
    name: "Knowledge Base Article First Block",
    preview: preview,
    renderer: KnowledgeBaseArticleFirstBlock,
    initialData: {
        title: "",
        subtitle: "",
        date: "09.09.2024",
        img: null,
        tags: [],
        time: "5 минут",
        timeDescription: "на чтение",
        author: "",
        logoAuthor: null,
        smallBlock: false,
        topMargin: "",
        bottomMargin: "",
        customMargin: false,
    },
    definition: {
        subTypes: {
            tagsLinks: {
                fields: [
                    {
                        id: "name",
                        type: "String",
                        name: "Name",
                    },
                    {
                        id: "link",
                        type: "String",
                        name: "Link",
                    },
                ],
            },
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "subtitle",
                type: "String",
                name: "Subtitle",
            },
            {
                id: "img",
                type: "Custom",
                customType: "Image",
                name: "Img",
            },
            {
                id: "date",
                type: "String",
                name: "Date",
            },
            {
                id: "time",
                type: "String",
                name: "Time"
            },
            {
                id: "timeDescription",
                type: "String",
                name: "Time Description",
            },
            {
                id: "tags",
                name: "Tags",
                type: "List",
                listType: "tagsLinks",
            },
            {
                id: "author",
                name: "Author",
                type: "String",
            },
            {
                id: "logoAuthor",
                name: "Logo Author",
                type: "Custom",
                customType: "Image",
            },
            {
                id: "smallBlock",
                type: "CheckBox",
                name: "Small Block",
            },
            {
                id: "customMargin",
                type: "CheckBox",
                name: "Custom Margin",
            },
            {
                id: "topMargin",
                type: "String",
                name: "Top Margin (px)",
            },
            {
                id: "bottomMargin",
                type: "String",
                name: "Bottom Margin (px)",
            },
        ],
    },
};
