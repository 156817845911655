import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./LocationUniversityBlock.module.css";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import cn from "classnames";
import { Map, MapComponentsProvider, MapPoint } from "../../ui/Map";
import Plus from "../../assets/icons/plus.svg";
import Minus from "../../assets/icons/subtract.svg";
import { BlockApiClient, Residence } from "../../api/blockApiClient";
import { ApiBaseUrl } from "../../api/apiClientBase";
import { useIntl } from 'react-intl';
import map from "../../assets/icons/Map.svg";
import list from "../../assets/icons/Row.svg";
import mapActive from "../../assets/icons/MapActive.svg";
import listActive from "../../assets/icons/RowActive.svg";
import Default from "../../assets/img/default.png";
import Supercluster from 'supercluster';
import { YMap as YMapType, LngLat, LngLatBounds } from "@yandex/ymaps3-types";

const apiClient = new BlockApiClient();

export interface Campus {
  name: string;
  address: string;
  description: string;
  nearby: string;
  latitude: number;
  longitude: number;
  country: string;
}

export interface LocationUniversityBlockProps {
  title: string;
  description: string;
  titleDescription: string;
  titleNearby: string;
  radius: number;
  mapText: string;
  listText: string;
  centerLongitude: number;
  centerLatitude: number;
  zoom: number;
  duration: number;
  apiKey: string;
  campuses: Campus[];
  topMargin?: string;
  bottomMargin?: string;
  customMargin?: boolean;
}

export const LocationUniversityBlock: React.FC<LocationUniversityBlockProps> = ({
  apiKey,
  title,
  description,
  titleDescription,
  titleNearby,
  radius,
  mapText,
  listText,
  centerLongitude,
  centerLatitude,
  zoom,
  duration,
  campuses = [],
  customMargin,
  topMargin,
  bottomMargin,
}) => {
  const intl = useIntl();
  const userLang = intl.locale;

  const [selectedCampus, setSelectedCampus] = useState<Campus | null>(null);
  const [expandedCampus, setExpandedCampus] = useState<string | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [mapCenter, setMapCenter] = useState<[number, number]>([centerLongitude, centerLatitude]);
  const [residences, setResidences] = useState<Residence[]>([]);
  const [gallery, setGallery] = useState<{ [key: number]: string }>({});
  const [activeResidence, setActiveResidence] = useState<Residence | null>(null);
  const [selectedView, setSelectedView] = useState("map");
  const [viewport, setViewport] = useState({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: zoom,
  });
  const prevCenterRef = useRef<[number, number] | null>(null);
  const currentZoomRef = useRef(zoom);
  const mapRef = useRef<YMapType | null>(null);
  const [superclusterInstance, setSuperclusterInstance] = useState<Supercluster | null>(null);
  const countries = Array.from(new Set((campuses || []).map(campus => campus.country)));
  const programmaticCenterChange = useRef(false);

  const filteredCampuses = selectedCountry
    ? campuses.filter((campus) => campus.country === selectedCountry)
    : campuses;

  const getResidenceData = (residence: Residence, lang: string) => {
    const availableLangs = Object.keys(residence.languages);
    const fallbackLang = "en";
    return residence.languages[availableLangs.includes(lang) ? lang : fallbackLang] || residence.languages[fallbackLang];
  };

  const fetchResidences = async (latitude: number, longitude: number) => {
    try {
      const data = await apiClient.getResidences(latitude, longitude, radius);
      setResidences(data);
      data.forEach(async (residence) => {
        try {
          const galleryIds = await apiClient.getGallery(residence.id);
          if (galleryIds.length > 0) {
            setGallery(prev => ({ ...prev, [residence.id]: `${ApiBaseUrl}/api/media/${galleryIds[0]}` }));
          }
        } catch (error) {
          console.error(`Ошибка загрузки галереи для резиденции ${residence.id}:`, error);
        }
      });
    } catch (error) {
      console.error("Ошибка загрузки резиденций:", error);
    }
  };

  useEffect(() => {
    if (selectedCampus) {
      fetchResidences(selectedCampus.latitude, selectedCampus.longitude);
      setMapCenter([selectedCampus.longitude, selectedCampus.latitude]);
    }
  }, [selectedCampus]);

  useEffect(() => {
    if (campuses.length > 0) {
      const firstCampus = campuses[0];
      setSelectedCampus(firstCampus);
      setMapCenter([firstCampus.longitude, firstCampus.latitude]);
      setSelectedCountry(firstCampus.country);
      fetchResidences(firstCampus.latitude, firstCampus.longitude);
    }
  }, [campuses]);

  const handleZoomChange = (newZoom: number) => {
    if (currentZoomRef.current === newZoom) return;
    currentZoomRef.current = newZoom;
    setViewport((prev) => ({
      ...prev,
      zoom: newZoom,
    }));
  };

  const handlePointClick = (residence: Residence, latitude: number, longitude: number) => {
    if (!latitude || !longitude) {
      console.error("Некорректные координаты:", latitude, longitude);
      return;
    }
    setMapCenter([longitude, latitude])
    setActiveResidence((prev) => (prev?.id === residence.id ? null : residence));
  };

  const handleCenterChange = (newCenter: LngLat) => {
    if (programmaticCenterChange.current) {
      return;
    }

    const threshold = 0.00001;

    const roundedCenter: [number, number] = [
      Math.round(newCenter[0] * 10000) / 10000,
      Math.round(newCenter[1] * 10000) / 10000,
    ];

    if (
      prevCenterRef.current &&
      Math.abs(prevCenterRef.current[0] - roundedCenter[0]) < threshold &&
      Math.abs(prevCenterRef.current[1] - roundedCenter[1]) < threshold
    ) {
      return;
    }
    prevCenterRef.current = roundedCenter;
    setMapCenter(roundedCenter);
    setViewport((prev) => ({
      ...prev,
      latitude: roundedCenter[1],
      longitude: roundedCenter[0],
    }));
  };

  const toggleExpand = (campus: Campus) => {
    setExpandedCampus(expandedCampus === campus.name ? null : campus.name);
    setSelectedCampus(campus);
    setMapCenter([campus.longitude, campus.latitude]);
  };

  const handleCampusClick = (campus: Campus) => {
    setSelectedCampus(campus);
    programmaticCenterChange.current = true;
    setMapCenter([campus.longitude, campus.latitude]);
    setViewport((prev) => ({
      ...prev,
      latitude: campus.latitude,
      longitude: campus.longitude,
    }));
    requestAnimationFrame(() => {
      setTimeout(() => {
        programmaticCenterChange.current = false;
      }, 500);
    });
  };

  useEffect(() => {
    const loadSupercluster = async () => {
      const { default: Supercluster } = await import('supercluster');
      const instance = new Supercluster({
        radius: 100,
        maxZoom: 16,
      });
      setSuperclusterInstance(instance);
    };

    loadSupercluster();
  }, []);

  const clusters = useMemo(() => {
    if (!superclusterInstance) return null;
    const points = residences.map((residence) => {
      const languageData = getResidenceData(residence, userLang);
      return {
        type: "Feature" as const,
        properties: { cluster: false, residence },
        geometry: {
          type: "Point" as const,
          coordinates: [
            parseFloat(languageData.location.lng),
            parseFloat(languageData.location.lat),
          ],
        },
      };
    });

    superclusterInstance.load(points);
    return superclusterInstance.getClusters([-180, -85, 180, 85], viewport.zoom);
  }, [residences, superclusterInstance, userLang, viewport.zoom]);

  const marginStyles = customMargin
    ? {
      marginTop: topMargin ? `${topMargin}px` : undefined,
      marginBottom: bottomMargin ? `${bottomMargin}px` : undefined,
    }
    : {};
  return (
    <div className="my-12" style={marginStyles}>
      <div className={cn(`flex flex-col justify-center align-center max-w-screen-xl w-full mx-auto items-center`)}>
        <div className={styles.location} dangerouslySetInnerHTML={{ __html: title }} />
        {description && <div className={styles.text} dangerouslySetInnerHTML={{ __html: description }} />}
      </div>
      <div>
        <div className="hidden md:flex flex-wrap gap-3 justify-center mb-10 mt-12 lg:mt-0">
          {countries.map((country) => (
            <button
              key={country}
              className={cn(styles.countryButton, {
                [styles.countryButtonActive]: selectedCountry === country,
              })}
              onClick={() => setSelectedCountry(selectedCountry === country ? null : country)}
            >
              {country}
            </button>
          ))}
        </div>
        <div className="md:hidden px-4 mt-10">
          <select
            className="w-full p-4 border rounded"
            style={{
              backgroundColor: "#EFEFEF"
            }}
            value={selectedCountry || ""}
            onChange={(e) => setSelectedCountry(e.target.value || null)}
          >
            {countries.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="md:hidden flex justify-center mt-4 px-4">
        <div className={styles.buttonMapList}>
          <button
            className={cn("flex-1 py-2 text-center rounded-full transition uppercase", { [styles.buttonMapListActive]: selectedView === "list" })}
            onClick={() => setSelectedView("list")}
          >
            {listText}
          </button>
          <button
            className={cn("flex-1 py-2 text-center rounded-full transition uppercase", { [styles.buttonMapListActive]: selectedView === "map" })}
            onClick={() => setSelectedView("map")}
          >
            {mapText}
          </button>
        </div>
      </div>
      <div className={"px-7 mb-6 hidden md:flex lg:hidden justify-center relative"}>
        <div className={cn("flex flex-row gap-6 w-full")}>
          <div className="flex flex-row gap-2 w-auto">
            <img src={selectedView === 'map' ? mapActive : map} width={16} height={16} className={"flex"} />
            <button
              className={cn(selectedView === 'map' && styles.activeMapList, "flex flex-1 z-50")}
              onClick={(event) => {
                event.preventDefault()
                setSelectedView('map');
              }}
            >
              {mapText}
            </button>
          </div>
          <div className="flex flex-row gap-2 justify-center w-2/4 md:w-auto" >
            <img src={selectedView === 'list' ? listActive : list} width={16} height={16} className={"flex"} />
            <button
              className={cn(selectedView === 'list' && styles.activeMapList, "flex flex-1 z-50")}
              onClick={(event) => {
                event.preventDefault()
                setSelectedView('list');
              }}
            >
              {listText}
            </button>
          </div>
        </div>
      </div>
      <div className={cn("max-w-screen-xl w-full my-0 mx-auto", styles.mapContainer)}>
        <div className={styles.campusList}>
          {filteredCampuses.map((campus, index) => (
            <div
              key={index}
              className={cn(styles.campusCard, {
                [styles.active]: selectedCampus === campus,
              })}
              onClick={() => handleCampusClick(campus)}
            >
              <div className="flex flex-row items-center gap-5">
                <div>
                  <h4 dangerouslySetInnerHTML={{ __html: campus.name }} />
                  <div className={cn(styles.campusCardText)} dangerouslySetInnerHTML={{ __html: campus.address }} />
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleExpand(campus);
                  }}
                >
                  {expandedCampus === campus.name ? <img src={Minus} width={24} height={24} /> : <img src={Plus} width={24} height={24} />}
                </button>
              </div>
              {expandedCampus === campus.name && (
                <div className={styles.campusDetails}>
                  <div className={styles.campusCardTitle} dangerouslySetInnerHTML={{ __html: titleDescription }} />
                  <div className={styles.campusCardText} dangerouslySetInnerHTML={{ __html: campus.description }} />
                  <div className={styles.campusCardTitle} dangerouslySetInnerHTML={{ __html: titleNearby }} />
                  <div className={styles.campusCardText} dangerouslySetInnerHTML={{ __html: campus.nearby }} />
                </div>
              )}
            </div>
          ))}
        </div>
        {selectedView === 'map' ? (
          <div className={cn(styles.map, "flex flex-col max-w-screen-xl w-full my-0 mx-auto")}>
            <MapComponentsProvider apiKey={apiKey}>
              <Map className={"h-full"}
                ref={mapRef}
                location={{ zoom: currentZoomRef.current, center: mapCenter, duration }}
                onZoomChange={handleZoomChange}
                onCenterChange={handleCenterChange}
              >
                {clusters && clusters.length > 0 && clusters.map((cluster, index) => {

                  if (!cluster || !cluster.geometry || !cluster.properties) return null;
                  const { coordinates } = cluster.geometry;
                  const isCluster = cluster.properties.cluster;

                  const clusterId = cluster.id as number | undefined;

                  if (isCluster && clusterId !== undefined) {
                    return (
                      <MapPoint
                        key={`cluster-${clusterId}`}
                        coordinates={[coordinates[0], coordinates[1]]}
                        shortInfo={<div className={styles.clusterMarker}>{cluster.properties.point_count}</div>}
                        onClick={() => {
                          if (!superclusterInstance) return;
                          const currentZoom = viewport.zoom;
                          const newZoom = Math.min(currentZoom + 1, 16);
                          handleZoomChange(newZoom);
                        }}
                      />
                    );
                  } else {
                    const residence = cluster.properties.residence;
                    if (!residence) return null;

                    const languageData = getResidenceData(residence, userLang);
                    if (!languageData) return null;

                    const imageUrl = gallery[residence.id];

                    return (
                      <MapPoint
                        key={index}
                        coordinates={[coordinates[0], coordinates[1]]}
                        shortInfo={<div className={styles.catalog__mapShortInfo}>€ {residence.price}</div>}
                        info={
                          <a href={`/ru/housing/${languageData.url}`}>
                            <div className={styles.cardMap}>
                              <div className={styles.cardMap__left}>
                                <div className={styles.cardMap__leftImg}>
                                  <img src={imageUrl || Default} />
                                </div>
                                <div className={styles.cardMap__right}>
                                  <div className={styles.cardMap__residTitle}>{languageData.name}</div>
                                  <div className="flex flex-row gap-2">
                                    {residence?.namedTraits['housing-accommodation']?.map((img: any) => {
                                      return (
                                        <img key={img.iconId} src={`${ApiBaseUrl}/api/media/scaled/${img.iconId}`} width={16} height={16} />
                                      );
                                    })}
                                  </div>
                                  <div className={styles.cardMap__rightPrice}>
                                    {intl.formatMessage({ id: "catalogItems_price_from" })}{" "}
                                    <b>
                                      <span>{residence?.price} </span>
                                      {intl.formatMessage({ id: "catalogItems_price_value" })}
                                    </b>{" "}
                                    {intl.formatMessage({ id: "catalogItems_price_month" })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        }
                        isActive={activeResidence?.id === residence.id}
                        onClick={() => handlePointClick(residence, parseFloat(languageData.location.lat), parseFloat(languageData.location.lng))}
                      />
                    );
                  }
                })}
                {selectedCampus && (
                  <MapPoint
                    key="centerMarker"
                    coordinates={[selectedCampus.longitude, selectedCampus.latitude]}
                    isActive={false}
                    centerPin={true}
                  />
                )}

              </Map>
            </MapComponentsProvider>
          </div>) : (
          <div className={cn(styles.campusListMobile, "lg:hidden")}>
            {filteredCampuses.map((campus, index) => (
              <div
                key={index}
                className={cn(styles.campusCard, {
                  [styles.active]: selectedCampus === campus,
                })}
                onClick={() => handleCampusClick(campus)}
              >
                <div className="flex flex-row items-center justify-between">
                  <div>
                    <h4 dangerouslySetInnerHTML={{ __html: campus.name }} />
                    <div className={cn(styles.campusCardText, "w-11/12")} dangerouslySetInnerHTML={{ __html: campus.address }} />
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(campus);
                    }}
                  >
                    {expandedCampus === campus.name ? <img src={Minus} width={24} height={24} /> : <img src={Plus} width={24} height={24} />}
                  </button>
                </div>
                {expandedCampus === campus.name && (
                  <div className={styles.campusDetails}>
                    <div className={styles.campusCardTitle} dangerouslySetInnerHTML={{ __html: titleDescription }} />
                    <div className={styles.campusCardText} dangerouslySetInnerHTML={{ __html: campus.description }} />
                    <div className={styles.campusCardTitle} dangerouslySetInnerHTML={{ __html: titleNearby }} />
                    <div className={styles.campusCardText} dangerouslySetInnerHTML={{ __html: campus.nearby }} />
                  </div>
                )}
              </div>
            ))}
            {/* {residences.map((item, index) => {
              const languageData = getResidenceData(item, userLang);
              if (!languageData) return null;
              const imageUrl = gallery[item.id];
              return (
                <a href={`/ru/housing/${languageData.url}`}>
                  <a className={cn(styles.card, "mx-6")}>
                    <div className={styles.card__top}>
                      <div className={styles.card__left}>
                        <div className={styles.cardMap__leftImgList}>
                          <img src={imageUrl || Default} />
                        </div>
                      </div>
                      <div className={styles.card__right + " pb-6"}>
                        <div className={styles.card__residTitle}>
                          {languageData?.name} {item.namedTraits.city && item.namedTraits.city[0]?.name}
                        </div>
                        <a
                          className={`flex items-start ${styles.multiImg__contentMap}`}
                          href={`https://www.google.com/maps/@${languageData.location?.lat},${languageData.location?.lng},20z`}
                          target={`_blank`}
                        >
                          <span className={styles.card__residLocation}>{languageData.location?.address?.value}</span>
                        </a>
                        {console.log("address: ", languageData.location)}
                        <div className={`flex flex-row gap-2 mt-5`}>
                          {item.namedTraits['housing-accommodation']?.map((img) => {
                            return (
                              <img src={`${ApiBaseUrl}/api/media/scaled/${img.iconId}`} width={16} height={16} />
                            )
                          })}
                        </div>
                        <div className={`${styles.card__rightPrice} mt-auto`}>
                          <div className={styles.cardMap__rightPrice}>
                            {intl.formatMessage({ id: "catalogItems_price_from" })}{" "}
                            <b>
                              <span>{item?.price} </span>
                              {intl.formatMessage({ id: "catalogItems_price_value" })}
                            </b>{" "}
                            {intl.formatMessage({ id: "catalogItems_price_month" })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.card__bottom}>
                      <div
                        className={`${styles.card__description}`}
                        dangerouslySetInnerHTML={{ __html: languageData.htmlDescription }}
                      />
                    </div>
                  </a>
                </a>
              )
            })} */}
          </div>
        )}
      </div>
    </div>
  )
};

export const LocationUniversityBlockInfo: TypedBlockTypeInfo<LocationUniversityBlockProps> = {
  id: "locationUniversityBlock",
  name: "Location University",
  preview: preview,
  renderer: LocationUniversityBlock,
  initialData: {
    apiKey: "",
    title: "",
    description: "",
    titleDescription: "Описание кампуса",
    titleNearby: "Что расположено рядом",
    radius: 5,
    mapText: "На карте",
    listText: "Списком",
    centerLatitude: 55.751603,
    centerLongitude: 37.6177,
    zoom: 10,
    duration: 300,
    campuses: [
      {
        name: "",
        address: "",
        description: "",
        nearby: "",
        latitude: 55.751603,
        longitude: 37.6177,
        country: "",
      }
    ],
    topMargin: "",
    bottomMargin: "",
    customMargin: false,
  },
  definition: {
    subTypes: {
      campus: {
        fields: [
          {
            id: "name",
            name: "Name",
            type: "Custom",
            customType: "Html",
          },
          {
            id: "address",
            name: "Address",
            type: "Custom",
            customType: "Html",
          },
          {
            id: "description",
            name: "Description",
            type: "Custom",
            customType: "Html",
          },
          {
            id: "nearby",
            name: "Nearby",
            type: "Custom",
            customType: "Html",
          },
          {
            id: "longitude",
            name: "Longitude",
            type: "Number",
          },
          {
            id: "latitude",
            name: "Latitude",
            type: "Number",
          },
          {
            id: "country",
            name: "Country",
            type: "String",
          },
        ]
      },
    },
    fields: [
      {
        id: "title",
        type: "Custom",
        customType: "Html",
        name: "Title",
      },
      {
        id: "description",
        type: "Custom",
        customType: "Html",
        name: "Description",
      },
      {
        id: "titleDescription",
        name: "Title Description",
        type: "Custom",
        customType: "Html",
      },
      {
        id: "titleNearby",
        name: "Title Nearby",
        type: "Custom",
        customType: "Html",
      },
      {
        id: "apiKey",
        type: "String",
        name: "Api Key",
      },
      {
        id: "radius",
        name: "Radius (km)",
        type: "Number",
      },
      {
        id: "centerLongitude",
        type: "Number",
        name: "CenterLongitude",
      },
      {
        id: "centerLatitude",
        type: "Number",
        name: "Center Latitude",
      },
      {
        id: "zoom",
        type: "String",
        name: "Zoom",
      },
      {
        id: "duration",
        type: "String",
        name: "Duration",
      },
      {
        id: "mapText",
        type: "String",
        name: "Map Text",
      },
      {
        id: "listText",
        type: "String",
        name: "List Text",
      },
      {
        id: "campuses",
        name: "Campuses",
        type: "List",
        listType: "campus",
      },
      {
        id: "customMargin",
        type: "CheckBox",
        name: "Custom Margin",
      },
      {
        id: "topMargin",
        type: "String",
        name: "Top Margin (px)",
      },
      {
        id: "bottomMargin",
        type: "String",
        name: "Bottom Margin (px)",
      },
    ],
  },
};
