import React, { useContext } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./ServicesIntroBlock.module.css";
import cn from "classnames";
import { ComponentHostContext } from "../index";

export interface ServicesIntroBlockElement {
    title: string;
    text: string;
    buttonText: string
}

export const ServicesIntroBlock = (props: ServicesIntroBlockElement) => {
    const cl = useContext(ComponentHostContext);
    return (
        <div className="flex my-10 items-center justify-center w-full">
            <div className={cn(styles.servicesIntroBlock__info, "max-w-screen-xl flex flex-col lg:px-12 gap-8 md:gap-12")}>
                <div className={cn(styles.servicesIntroBlock__title, "mb-6 md:mb-10")}>
                    {props.title}
                </div>
                <div className={cn(styles.servicesIntroBlock__text)}>
                    {props.text}
                </div>
                <button className={cn(styles.servicesIntroBlock__button, "flex self-center lg:self-start")} type="submit" onClick={() =>cl?.showContactUsFormServicePink()}>
                    {props.buttonText}
                </button>
            </div>
        </div>
    )
}

export const ServicesIntroBlockInfo: TypedBlockTypeInfo<ServicesIntroBlockElement> = {
    id: "ServicesIntroBlock",
    name: "ServicesIntroBlock",
    preview: preview,
    renderer: ServicesIntroBlock,
    initialData: {
        title: "string",
        text: "string",
        buttonText: "string"
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "buttonText",
                type: "String",
                name: "Button Text",
            },
        ]
    }
}
