import { ApiClientBase } from "./apiClientBase";

export type RequestFormType = {
    name: string;
    email: string;
    tel: string;
    contactWay?: string;
    com?: string;
};

export interface AdminTraitItemDto {
    namesJson: { [name: string]: string };
    info: string | null
}

export interface Residence {
    price: number;
    namedTraits: {
        [key: string]: Array<{
            id: number;
            name: string;
            identifier: string | null;
            iconId: number | null;
        }>;
    };
    languages: {
        [key: string]: {
            name: string;
            htmlDescription: string;
            url: string;
            location: {
                lat: string;
                lng: string;
                address: {
                    value?: string;
                    city?: string;
                    street?: string;
                    commune?: string;
                    country?: string;
                    metropole?: string;
                    postal_code?: string;
                };
            };
            popular: boolean;
            locationDescription: string;
            whyThisHousing: string;
        };
    };
    imageId: number;
    customId: number;
    id: number;
}

export class BlockApiClient extends ApiClientBase {
    async sendConsultationRequest(req: RequestFormType) {
        const url = `call/request`;
        return await this.sendRequest(url, req);
    }

    async getAdminCities(id: string) {
        return await this.sendRequest<{
            info?: string | null, namesJson: {
                en?: string, cn?: string, esp?: string,
                fr?: string, ru?: string
            }
        }[]>("traits/cities-by-id/" + id)
    }

    async getUniversities(lang: string, pageSize = 1000, pageNumber = 0, filters: any[] = []) {
        const url = `catalog/university/search/${lang}`;
        const body = {
            pageSize,
            pageNumber,
            filters,
            sortBy: "name",
            sortOrder: "asc"
        };
        return await this.sendRequest(url, body, "POST");
    }

    async getCourses(lang: string, pageSize = 1000, pageNumber = 0, filters: any[] = []) {
        const url = `catalog/course/search/${lang}`;
        const body = {
            pageSize,
            pageNumber,
            filters,
            sortBy: "name",
            sortOrder: "asc"
        };
        return await this.sendRequest(url, body, "POST");
    }

    async getHouses(lang: string, pageSize = 100, pageNumber = 0, filters: any[] = [], priceMin = 100, priceMax = 5000) {
        const url = `catalog/housing/search/${lang}`;
        const body = {
            pageSize,
            pageNumber,
            filters,
            sortBy: "price",
            sortOrder: "asc",
            priceMin,
            priceMax
        };
        return await this.sendRequest(url, body, "POST");
    }

    async getResidences(latitude: number, longitude: number, radius: number): Promise<Residence[]> {
        const url = `v1/map/coordinates?searchRadius=${radius}&latitude=${latitude}&longitude=${longitude}`;
        return await this.sendRequest(url, null, "GET");
    }

    async getGallery(housingId: number): Promise<number[]> {
        const url = `housing/gallery/${housingId}`;
        return await this.sendRequest<number[]>(url, null, "GET");
    }
}
