import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./TextAndImageBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface TextAndImageBlockElement {
    title: string;
    highlight: string;
    text: string;
    backgroundColor?: "pink" | "blue";
    img: number | null;
    icon: number | null;
    description: string;
}

const normalizeText = (text: string): string => {
    return text.toLowerCase().replace(/\s+/g, ' ').trim();
};

const highlightMatch = (title: string, highlight: string, backgroundColor?: string): JSX.Element[] => {
    if (!highlight) return [<span key="0">{title}</span>];

    const normalizedTitle = normalizeText(title);
    const normalizedHighlight = normalizeText(highlight);

    const regex = new RegExp(`(${normalizedHighlight.split(' ').join('\\s*')})`, "gi");
    const parts = normalizedTitle.split(regex);

    const highlightStyle =
        backgroundColor === "blue"
            ? { backgroundColor: "rgba(0, 48, 157, 1)", color: "rgba(255, 255, 255, 1)" }
            : { backgroundColor: "rgba(250, 83, 84, 1)", color: "rgba(255, 255, 255, 1)" };

    let originalIndex = 0;

    return parts.map((part, index) => {
        const originalPart = title.substring(originalIndex, originalIndex + part.length);
        originalIndex += part.length;

        return regex.test(part) ? (
            <span key={index} style={highlightStyle}>
                {originalPart}
            </span>
        ) : (
            <span key={index}>{originalPart}</span>
        );
    });
};

export const TextAndImageBlock = (props: TextAndImageBlockElement) => {
    return (
        <div style={props.backgroundColor === "blue" ? { backgroundColor: "rgba(206, 225, 243, 1)" } : { backgroundColor: "#F3D7E3" }}>
            <div className="flex flex-col lg:flex-row justify-between mx-auto w-full max-w-screen-xl">
                <div className="w-full lg:w-6/12 mb-16 lg:mb-0">
                    {props.img && <img className="w-full" src={`${ApiBaseUrl}/api/media/${props.img}`} alt="" />}
                </div>
                <div className="mx-auto w-full lg:w-6/12 flex flex-col justify-evenly px-6 lg:ml-20 flex flex-col items-center lg:items-start">
                    <div className="flex flex-col items-center lg:items-start">
                        <h2 className={cn(style.title, "w-10/12 md:w-8/12 lg:w-full")}>{highlightMatch(props.title, props.highlight, props.backgroundColor)}</h2>
                        <p className={cn(style.main_text, "w-10/12 lg:w-full")}>{props.text}</p>
                    </div>
                    <div className={cn(style.main_text, "mb-16 lg:mb-0 w-10/12")}>
                        {props.icon && <img src={`${ApiBaseUrl}/api/media/${props.icon}`} alt="" width="40" height="40" className="mb-6 hidden lg:block"/>}
                        <div className={cn("mt-2 lg:mt-0")}>{props.description}</div>
                    </div>
                </div>
                <div>

                </div>
            </div>
        </div>
    );
}

export const TextAndImageBlockInfo: TypedBlockTypeInfo<TextAndImageBlockElement> = {
    id: "TextAndImageBlock",
    name: "TextAndImageBlock",
    preview: preview,
    renderer: TextAndImageBlock,
    initialData: {
        title: "string",
        highlight: "string",
        text: "string",
        backgroundColor: "pink",

        img: null,
        icon: null,
        description: "string",
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "highlight",
                type: "String",
                name: "Highlight"
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "backgroundColor",
                type: "Radio",
                name: "Background Color",
                possibleValues: [
                    {
                        id: "pink",
                        name: "pink",
                    },
                    {
                        id: "blue",
                        name: "blue",
                    },
                ],
            },
            {
                id: "img",
                type: "Custom",
                customType: "Image",
                name: "Img",
            },
            {
                id: "icon",
                type: "Custom",
                customType: "Image",
                name: "Icon",
            },
            {
                id: "description",
                type: "String",
                name: "Description",
            },
        ],
    },
};
