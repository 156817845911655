// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextAndImageBlock_title__1qzv_{\n  font-size: 42px;\n  line-height: 54.6px;\n  font-weight: 600;\n  margin-bottom: 24px;\n}\n\n.TextAndImageBlock_main_text__RycfN {\n  font-size: 18px;\n  line-height: 21.6px;\n  font-weight: 400;\n}\n\n@media (min-width: 767px) and (max-width: 1023px){\n  .TextAndImageBlock_title__1qzv_, .TextAndImageBlock_main_text__RycfN{\n    text-align: center;\n  }\n\n  .TextAndImageBlock_main_text__RycfN{\n    font-size: 14px;\n    line-height: 16.8px;\n  }\n}\n\n@media (max-width: 767px){\n  .TextAndImageBlock_title__1qzv_{\n    font-size: 26px;\n    line-height: 33.8px;\n    text-align: center;\n  }\n\n  .TextAndImageBlock_main_text__RycfN{\n    text-align: center;\n  }\n}", "",{"version":3,"sources":["webpack://../components/src/blocks/TextAndImageBlock/TextAndImageBlock.module.css"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACvB;;AAEA;EACI,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AACpB;;AAEA;EACI;IACI,kBAAkB;EACtB;;EAEA;IACI,eAAe;IACf,mBAAmB;EACvB;AAEJ;;AAEA;EACI;IACI,eAAe;IACf,mBAAmB;IACnB,kBAAkB;EACtB;;EACA;IACI,kBAAkB;EACtB;AACJ","sourcesContent":[".title{\n    font-size: 42px;\n    line-height: 54.6px;\n    font-weight: 600;\n    margin-bottom: 24px;\n}\n\n.main_text {\n    font-size: 18px;\n    line-height: 21.6px;\n    font-weight: 400;\n}\n\n@media (min-width: 767px) and (max-width: 1023px){\n    .title, .main_text{\n        text-align: center;\n    }\n\n    .main_text{\n        font-size: 14px;\n        line-height: 16.8px;\n    }\n    \n}\n\n@media (max-width: 767px){\n    .title{\n        font-size: 26px;\n        line-height: 33.8px;\n        text-align: center;\n    }\n    .main_text{\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "TextAndImageBlock_title__1qzv_",
	"main_text": "TextAndImageBlock_main_text__RycfN"
};
export default ___CSS_LOADER_EXPORT___;
