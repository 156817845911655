import {
  PageEditorCellDialogStore,
  PageEditorCellStore,
  PageEditorRowStore,
  PageEditorStore,
  PageLanguageEditorStore,
  PageRowsEditorStore,
} from "./PageEditorStore";
import { useObserver } from "mobx-react";
import { AdminRemoteUiHtmlEditor, AdminRemoteUiHtmlEditorStore } from "src/components/remoteui/AdminRemoteUiHtmlEditor";
import {
  AdminRemoteUiImageFieldEditor,
  AdminRemoteUiImageFieldStore,
  ImagePickerWithLabel,
} from "src/components/remoteui/AdminRemoteUiImageEditor";
import {
  AdminRemoteUiVideoFieldEditor,
  AdminRemoteUiVideoFieldStore,
  VideoPicker,
} from "src/components/remoteui/AdminRemoteUiVideoEditor";
import { AvailableBlocks, BlockPresenter } from "@project/components/src/blocks";
import { AdminButton } from "src/components/common/AdminButton";
import { IRemoteUiData, IRemoteUiEditorCustomization, RemoteUiEditor } from "@kekekeks/remoteui/src";
import { AdminSlider } from "src/components/common/AdminSlider";
import { AdminOverlayDialog } from "src/components/common/AdminOverlayDialog";
import { bind, dmap } from "src/utils/util";
import { AdminTabControl } from "src/components/common/AdminTabControl";
import "@kekekeks/remoteui/src/RemoteUiEditor.css";
import { AdminTextBox } from "src/components/common/AdminTextBox";
import { AllLanguages } from "@project/components/src/utils/langs";
import grid from "@project/components/src/styles/grid.module.css";
import { AdminRemoteUiRowsEditor, AdminRemoteUiRowsStore } from "src/components/remoteui/AdminRemoteUiRowsEditor";
import { useEffect, useState } from "react";

import styles from "./PageEditor.module.css";
import { AdminRemoteUiDropdownEditor, AdminRemoteUiDropdownEditorStore } from "../remoteui/AdminRemoteUiDropdownEditor";
import { RouterLink } from "mobx-state-router";
import { AdminRouteNames } from "src/pages/Admin/AdminRoutes";

const PageEditorCell = (props: { store: PageEditorCellStore }) => {
  const s = props.store;
  const rowStore = s.rowStore;
  return useObserver(() => (
    <div>
      <div className="bg-white">
        <div className="relative">
          <div className={`py-12`}>
            {s.blockData == null ? null : <BlockPresenter blockType={s.blockType} blockData={s.blockData} />}
          </div>
          <div className="absolute top-0 right-0">
            {rowStore.cells.indexOf(s) > 0 && (
              <AdminButton color={"default"} className="mr-2" onClick={() => rowStore.moveCellUp(s)}>
                ↑
              </AdminButton>
            )}
            {rowStore.cells.indexOf(s) < rowStore.cells.length - 1 && (
              <AdminButton color={"default"} className="mr-2" onClick={() => rowStore.moveCellDown(s)}>
                ↓
              </AdminButton>
            )}
            <AdminButton color={"primary"} className="mr-2" onClick={() => s.copyBlockData()}>Copy</AdminButton>
            <AdminButton color={"primary"} className="mr-2" onClick={() => s.pasteBlockData()}>Paste</AdminButton>
            <AdminButton color={"primary"} onClick={() => { s.triggerEdit(), s.editor.cellEditor?.preventCloseDialog() }}>
              ...
            </AdminButton>
            &nbsp;
            <AdminButton color={"danger"} onClick={() => s.triggerDelete()}>
              X
            </AdminButton>
          </div>
        </div>
      </div>
    </div>
  ));
};

const PageEditorRow = (props: { store: PageEditorRowStore }) => {
  const s = props.store;
  return useObserver(() => (
    <div className="bg-gray-100 pt-2 pb-2">
      <div style={{ width: "100%" }}>
        <div>
          <div>
            <div style={{ width: "100%" }} className={`p-4`}>
              <div className={`flex mb-3`}>
                <div className={`flex flex-col mr-6`}>
                  <span>MaxWidth</span>
                  <label className={`flex items-center mr-6`}>
                    <input
                      type={"radio"}
                      name={"width"}
                      onChange={(e) => (props.store.maxWidth = e.target.value)}
                      value={"100%"}
                      checked={props.store.maxWidth === "100%"}
                    />
                    <span className={`ml-3`}>100%</span>
                  </label>
                  <label className={`flex items-center mr-6`}>
                    <input
                      type={"radio"}
                      name={"width"}
                      onChange={(e) => (props.store.maxWidth = e.target.value)}
                      value={"1260px"}
                      checked={props.store.maxWidth === "1260px"}
                    />
                    <span className={`ml-3`}>1260px</span>
                  </label>
                </div>
                <div className={`flex flex-col`}>
                  <span>backGround</span>
                  <label className={`flex items-center mr-6`}>
                    <input
                      type={"radio"}
                      name={"bg"}
                      onChange={(e) => (props.store.backGround = e.target.value)}
                      value={`#fff`}
                      checked={props.store.backGround === "#fff"}
                    />
                    <span className={`ml-3`}>White</span>
                  </label>
                  <label className={`flex items-center mr-6`}>
                    <input
                      type={"radio"}
                      name={"bg"}
                      onChange={(e) => (props.store.backGround = e.target.value)}
                      value={`#F9FAFD`}
                      checked={props.store.backGround === "#F9FAFD"}
                    />
                    <span className={`ml-3`}>White-Gray</span>
                  </label>
                </div>
                <div className={`flex flex-col`}>
                  <span>Show Row:</span>
                  <label className={`flex items-center mr-6`}>
                    <input
                      type={"radio"}
                      name={"hideRow"}
                      onChange={(e) => (props.store.hide = !Boolean(e.target.value))}
                      checked={!props.store.hide}
                    />
                    <span className={`ml-3`}>Show</span>
                  </label>
                  <label className={`flex items-center mr-6`}>
                    <input
                      type={"radio"}
                      name={"hideRow"}
                      onChange={(e) => (props.store.hide = Boolean(e.target.value))}
                      checked={props.store.hide}
                    />
                    <span className={`ml-3`}>Hide</span>
                  </label>
                </div>
                <div className={`flex flex-col`}>
                  <span>Vertical Align:</span>
                  <label className={`flex items-center mr-6`}>
                    <input
                      type={"radio"}
                      name={"verticalAlign"}
                      value={"start"}
                      onChange={(e) => (props.store.vertical = e.target.value)}
                      checked={props.store.vertical === "start"}
                    />
                    <span className={`ml-3`}>Top</span>
                  </label>
                  <label className={`flex items-center mr-6`}>
                    <input
                      type={"radio"}
                      name={"verticalAlign"}
                      value={"center"}
                      onChange={(e) => (props.store.vertical = e.target.value)}
                      checked={props.store.vertical === "center"}
                    />
                    <span className={`ml-3`}>Center</span>
                  </label>
                  <label className={`flex items-center mr-6`}>
                    <input
                      type={"radio"}
                      name={"verticalAlign"}
                      value={"end"}
                      onChange={(e) => (props.store.vertical = e.target.value)}
                      checked={props.store.vertical === "end"}
                    />
                    <span className={`ml-3`}>Bottom</span>
                  </label>
                </div>
              </div>
              <div className={`flex flex-wrap items-${props.store.vertical || "start"}`}>
                {props.store.cells.map((cell, i) => (
                  <div
                    className={`inline-block flex-shrink-0 ${grid["col-" + cell.size]
                      } border border-blue-400 box-border relative py-10`}
                    style={{ verticalAlign: "top" }}
                    key={cell.blockType + i}
                  >
                    <PageEditorCell key={i.toString()} store={cell} />
                  </div>
                ))}
                <div className="inline-block m-4 flex-shrink-0 self-end">
                  <AdminButton color={"primary"} onClick={() => props.store.showBlockTypes()}>
                    +
                  </AdminButton>
                </div>
              </div>
            </div>
            <div style={{ width: "0%", verticalAlign: "top" }}>
              <AdminButton color={"danger"} onClick={() => props.store.remove()}>
                X
              </AdminButton>
              {props.store.canMoveUp ? (
                <>
                  &nbsp;
                  <AdminButton color={"default"} onClick={() => s.moveUp()}>
                    ↑
                  </AdminButton>
                </>
              ) : null}
              {props.store.canMoveDown ? (
                <>
                  &nbsp;
                  <AdminButton color={"default"} onClick={() => s.moveDown()}>
                    ↓
                  </AdminButton>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};

class RemoteUiCustomization implements IRemoteUiEditorCustomization {
  getEditorFor(store: IRemoteUiData): any {
    if (store instanceof AdminRemoteUiHtmlEditorStore) return <AdminRemoteUiHtmlEditor store={store} />;
    if (store instanceof AdminRemoteUiImageFieldStore) return <AdminRemoteUiImageFieldEditor store={store} />;
    if (store instanceof AdminRemoteUiVideoFieldStore) return <AdminRemoteUiVideoFieldEditor store={store} />;
    if (store instanceof AdminRemoteUiRowsStore) return <AdminRemoteUiRowsEditor store={store} />;
    if (store instanceof AdminRemoteUiDropdownEditorStore) return <AdminRemoteUiDropdownEditor store={store} />;
    return null;
  }
}

const PageEditorCellDialog = (props: { store: PageEditorCellDialogStore }) => {
  const [showTypes, setShowTypes] = useState(false);
  const [currentType, setCurrentType] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setCurrentType(AvailableBlocks.findIndex((el) => el.id === props.store.blockType));
  }, [props.store.blockType]);

  useEffect(() => {
    if (!props.store.isEditing) {
      setShowTypes(true);
    }
  }, [props.store.isEditing]);


  return useObserver(() => (
    <div className="h-full">
      <div className={`flex`}>
        <div className={`mr-10`}>
          Size:
          <br />
          <AdminSlider min={1} max={12} value={props.store.size} onChange={(v) => (props.store.size = v)} />
        </div>
        <div className={`flex flex-col`}>
          <span>Show block:</span>
          <label className={`flex items-center mr-6`}>
            <input
              type={"radio"}
              name={"hideCell"}
              onChange={(e) => (props.store.hide = !Boolean(e.target.value))}
              checked={!props.store.hide}
            />
            <span className={`ml-3`}>Show</span>
          </label>
          <label className={`flex items-center mr-6`}>
            <input
              type={"radio"}
              name={"hideCell"}
              onChange={(e) => (props.store.hide = Boolean(e.target.value))}
              checked={props.store.hide}
            />
            <span className={`ml-3`}>Hide</span>
          </label>
        </div>
      </div>
      Type: {AvailableBlocks[currentType].name}
      <br />
      <AdminButton color={"primary"} onClick={() => setShowTypes(true)}>
        Change Type
      </AdminButton>
      {showTypes && (
        <AdminOverlayDialog cancel={() => setShowTypes(false)} >
          <div style={{ width: "90vw" }}>
            <input
              type="text"
              placeholder="Search blocks..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={"m-5 p-3 border-2 rounded border-grey-800"}
              style={{ width: "98%" }}
            />
            <div className={styles.types}>
              {AvailableBlocks.filter((b) =>
                b.name.toLowerCase().includes(searchQuery.toLowerCase())
              ).map((b) => (
                <div
                  key={b.id}
                  className={`${b.id === props.store.blockType ? styles.active : ""}`}
                  onClick={() => {
                    props.store.blockType = b.id;
                    setShowTypes(false);
                  }}
                >
                  {b.preview ? (
                    <img src={b.preview} alt={b.preview} />
                  ) : (
                    <span>No preview</span>
                  )}
                  <p>{b.name}</p>
                </div>
              ))}
            </div>
          </div>
        </AdminOverlayDialog>
      )}
      {props.store.currentEditor == null ? null : (
        <div style={{
          height: "85vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-evenly"
        }}>
          <div
            style={{
              margin: "5px",
              width: "100%",
              maxWidth: "90vw",
              minWidth: "600px",
              maxHeight: "90vh",
              overflow: "scroll",
            }}
          >
            <RemoteUiEditor store={props.store.currentEditor} customization={new RemoteUiCustomization()} />
          </div>
          <br />
          <AdminButton color={"primary"} onClick={() => props.store.save()}>
            Save
          </AdminButton>
        </div>
      )}
    </div>
  ));
};

export const PageRowsEditor = (props: { store: PageRowsEditorStore }) => {
  const s = props.store;

  return useObserver(() => (
    <>
      {s.cellEditor == null ? null : (
        <AdminOverlayDialog cancel={() => (s.cellEditor = null)} isEditor={true}>
          <PageEditorCellDialog store={s.cellEditor} />
        </AdminOverlayDialog>
      )}

      {s.rows.map((r, i) => (
        <PageEditorRow key={i.toString()} store={r} />
      ))}
      {/* <div className="content-center mt-8">
        <AdminButton color="primary" onClick={() => s.addRow()}>
          Add row
        </AdminButton>
      </div> */}
    </>
  ));
};

export const PageLanguageEditor = (props: {
  store: PageLanguageEditorStore;
  availableLangs: { lang: string; name: string }[];
  createPage: (copyFrom?: string) => void;
  previewImage: AdminRemoteUiImageFieldStore;
  smallPreviewImage: AdminRemoteUiImageFieldStore;
  widePreviewImage: AdminRemoteUiImageFieldStore;
  previewVideo: AdminRemoteUiVideoFieldStore;
}) => {
  const [selectedLang, setSelectedLang] = useState("en");
  return useObserver(() => (
    <div className={`flex flex-col`}>
      <div className={`my-4`}>
        <div className="content-center my-4">
          {props.availableLangs.length == 0 ? null : (
            <>
              Copy from
              <select value={selectedLang} onChange={(e) => setSelectedLang(e.target.value)}>
                {props.availableLangs.map((l) => (
                  <option value={l.lang}>{l.name}</option>
                ))}
              </select>
              <br />
            </>
          )}

          <AdminButton
            color="primary"
            onClick={() => {
              const confirmAction = window.confirm("Are you sure you want to copy the page version?");
              if (confirmAction) {
                props.createPage(selectedLang);
              }
            }}
          >
            Copy Page Version
          </AdminButton>
        </div>
        {props.store.metadata?.meta.map((el, index) => (
          <div key={index + "metadata"} className={`flex items-end`}>
            <label className={`mr-2`}>
              <span>Name</span>
              <input
                type="text"
                className={`form-control`}
                value={el.name}
                onChange={(e) => (el.name = e.target.value)}
              />
            </label>
            <label className={`mr-2`}>
              <span>Property</span>
              <input
                type="text"
                className={`form-control`}
                value={el.property}
                onChange={(e) => (el.property = e.target.value)}
              />
            </label>
            <label className={`mr-2`}>
              <span>Content</span>
              <input
                type="text"
                className={`form-control`}
                value={el.content}
                onChange={(e) => (el.content = e.target.value)}
              />
            </label>
            <button
              onClick={() => props.store.removeMeta(index)}
              className={`text-white  font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black`}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          onClick={() => props.store.addMeta()}
          className={`text-white mt-4 font-bold py-2 px-4 rounded inline-block bg-blue-500 hover:bg-blue-100 hover:text-black`}
        >
          Add meta
        </button>
      </div>
      <AdminTextBox id={"title"} label="Title" {...bind(props.store, "title")} />
      <AdminTextBox id={"url"} label="Url" {...bind(props.store, "url")} />
      <ImagePickerWithLabel store={props.previewImage} title={"Preview image:"} />
      <ImagePickerWithLabel store={props.smallPreviewImage} title={"Small Preview image:"} />
      <ImagePickerWithLabel store={props.widePreviewImage} title={"Wide Preview image:"} />
      <VideoPicker store={props.previewVideo} title={"Preview video:"} />
      <br />
      <PageRowsEditor store={props.store} />
    </div>
  ));
};

const CreatePage = (props: {
  availableLangs: { lang: string; name: string }[];
  createPage: (copyFrom?: string) => void;
}) => {
  const noneLang = "<none>";
  const [selectedLang, setSelectedLang] = useState(noneLang);
  return (
    <div className="content-center m-4">
      {props.availableLangs.length == 0 ? null : (
        <>
          Copy from
          <select value={selectedLang} onChange={(e) => setSelectedLang(e.target.value)}>
            <option value={noneLang}>Create new</option>
            {props.availableLangs.map((l) => (
              <option value={l.lang}>{l.name}</option>
            ))}
          </select>
          <br />
        </>
      )}

      <AdminButton
        color="primary"
        onClick={() => props.createPage(selectedLang == noneLang ? undefined : selectedLang)}
      >
        Create Page Version
      </AdminButton>
    </div>
  );
};

export const PageEditor = (props: { store: PageEditorStore | any }) => {
  const s = props.store;
  return useObserver(() => {
    const availableLangs = dmap(AllLanguages, (id, lang) => ({
      lang: id,
      name: lang.title,
      isAvailable: props.store.langs[id] != null,
    })).filter((l) => l.isAvailable);
    return (
      <div>
        <div className="flex flex-row justify-between w-full">
          <AdminButton color="save" onClick={() => s.save()}>
            Save
          </AdminButton>{" "}
          <div>
            {s.id && (
              <RouterLink routeName={AdminRouteNames.pageTraitEditPage} params={{ id: s.id }}>
                <AdminButton color={"primary"}> Traits editor </AdminButton>
              </RouterLink>
            )}
            {s.id && (
              <AdminButton
                color="primary"
                onClick={() => {
                  s.copyPageAsTemplate()
                }}
                className="ml-3"
              >Copy the template</AdminButton>
            )}
          </div>
          {s.id && (
            <AdminButton
              color="danger"
              onClick={() => {
                const confirmAction = window.confirm("Are you sure you want to delete this page?");
                if (confirmAction) {
                  try {
                    s.deletePage(s.id);
                    alert("Page deleted successfully!");
                    window.location.href = "/pages";
                  } catch (error) {
                    console.error("Error deleting page:", error);
                    alert("Failed to delete the page.");
                  }
                }
              }}
              className="mr-10"
            >Delete</AdminButton>
          )}
        </div>
        <br />
        <AdminRemoteUiDropdownEditor store={props.store.pageType} label={"Page type: "} />
        <AdminTabControl
          tabs={dmap(AllLanguages, (lang, data) => ({
            id: lang,
            title: data.title,
            renderer:
              props.store.langs[lang] == null
                ? () => (
                  <CreatePage availableLangs={availableLangs} createPage={(copyFrom) => s.addLang(lang, copyFrom)} />
                )
                : () => (
                  <PageLanguageEditor
                    store={props.store.langs[lang]}
                    availableLangs={availableLangs}
                    createPage={(copyFrom) => s.addLang(lang, copyFrom)}
                    previewImage={props.store.previewImage}
                    smallPreviewImage={props.store.smallPreviewImage}
                    widePreviewImage={props.store.widePreviewImage}
                    previewVideo={props.store.previewVideo}
                  />
                ),
          }))}
        />
      </div>
    );
  });
};
