import React, { useState } from "react";
import cn from "classnames";

import styles from "./NewTabs.module.css";
import { NewSelect } from "../newSelect/NewSelect";

interface NewTabsProps {
  titles: string[];
  components: any;
}

const NewTabs = ({ titles, components }: NewTabsProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleSelectChange = (value: string, id: number | string) => {
    setActiveTab(Number(id));
  };

  return (
    <div className="w-full">
      {titles.length > 1 && (
        <>
          <div className={cn(styles.tabs, "p-1 hidden md:flex")}>
            <div className="flex w-max">
              {titles.map((title, ind) => (
                <div
                  key={ind}
                  className={cn(ind === activeTab ? styles.active : "")}
                  onClick={() => setActiveTab(ind)}
                >
                  {title}
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white md:hidden py-1 pl-3 mx-1">
            <NewSelect
              value={titles[activeTab]}
              options={titles.map((title, ind) => ({ name: title, id: ind }))}
              selectChange={handleSelectChange}
            />
          </div>
        </>
      )}
      <div className={cn(styles.NewSearchBlock, "flex flex-col w-full")}>
        <div className={cn(styles.content, "py-1 px-1")}>
          {components.map((comp: any, ind: number) => (
            <div key={ind} className={cn(ind === activeTab ? styles.active : "")}>
              {comp}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewTabs;
