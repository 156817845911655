import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./ResultsWorkBlock.module.css";
import cn from "classnames";
import { ApiBaseUrl } from "../../api/apiClientBase";

export interface ResultsWorkBlockElement {
    header: string;
    img: number | null;
    sideImg?: "left" | "right";
    text: string;
    titleTop: string;
    textTop: string;
    titleBottom: string;
    textBottom: string;
}

export const ResultsWorkBlock: React.FC<ResultsWorkBlockElement> = ({ header, img, sideImg, text, titleTop, textTop, titleBottom, textBottom }) => {
    const flexDirectionClass = sideImg === "right" ? "lg:flex-row" : "lg:flex-row-reverse";
    return (
        <div className={cn(style.container)}>
            <div className={cn(`flex flex-col-reverse items-center justify-between mx-auto w-full max-w-screen-xl ${flexDirectionClass}`)}>
                <div className={cn(style.content, "flex flex-col justify-around")}>
                    <div className="w-full lg:mb-40">
                        <h2>{header}</h2>
                        {text && <div className="mt-4">{text}</div>}
                    </div>
                    <div className="w-full">
                        <h3>{titleTop}</h3>
                        <span>{textTop}</span>
                        <h3>{titleBottom}</h3>
                        <span>{textBottom}</span>
                    </div>
                </div>
                {img && <img src={`${ApiBaseUrl}/api/media/${img}`} className="w-full lg:w-6/12" alt="" />}
            </div>
        </div>
    )
}

export const ResultsWorkBlockInfo: TypedBlockTypeInfo<ResultsWorkBlockElement> = {
    id: "ResultsWorkBlock",
    name: "ResultsWorkBlock",
    preview: preview,
    renderer: ResultsWorkBlock,
    initialData: {
        header: "",
        img: null,
        sideImg: "right",
        text: "",
        titleTop: "",
        textTop: "",
        titleBottom: "",
        textBottom: "",
    },
    definition: {
        fields: [
            {
                id: "header",
                type: "String",
                name: "Header",
            },
            {
                id: "img",
                name: "Image",
                type: "Custom",
                customType: "Image",
            },
            {
                id: "sideImg",
                name: "Side Img",
                type: "Radio",
                possibleValues: [
                    {
                        id: "left",
                        name: "Left",
                    },
                    {
                        id: "right",
                        name: "Right",
                    }
                ],
            },
            {
                id: "text",
                name: "Text",
                type: "String",
            },
            {
                id: "titleTop",
                name: "Title Top",
                type: "String",
            },
            {
                id: "textTop",
                name: "Text Top",
                type: "String",
            },
            {
                id: "titleBottom",
                name: "Title Bottom",
                type: "String",
            },
            {
                id: "textBottom",
                name: "Text Bottom",
                type: "String",
            },
        ],
    },
};
