import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import styles from "./CompanyDescriptionBlock.module.css";
import cn from "classnames";
import {ApiBaseUrl} from "../../api/apiClientBase";

export interface CompanyDescriptionBlockElement {
    title: string;
    text: string;
    icon: null | number;
    bottomText: string;
    extraBottomText: string;
    image: null | number
}

export const CompanyDescriptionBlock = (props: CompanyDescriptionBlockElement) => {
    return (
        <div className="flex flex-col lg:flex-row lg:p-12 p-4 max-w-screen-xl justify-between gap-10 mx-auto my-10 lg:my-0 ">
            <div className={cn(styles.aboutCompanyBlock__info, "flex flex-col justify-between lg:items-start items-center")}>
                <div className={"flex flex-col gap-5 items-center lg:items-start"}>
                    <div className={cn(styles.companyDescriptionBlock__title)}>
                        {props.title}
                    </div>
                    <div className={cn(styles.companyDescriptionBlock__text)}>
                        {props.text}
                    </div>
                </div>
                <div className={cn("flex flex-col gap-5 lg:items-start items-center", styles.companyDescriptionBlock__bottom)}>
                    {props.icon && <img src={`${ApiBaseUrl}/api/media/${props.icon}`} alt="" className={cn(styles.companyDescriptionBlock__icon, "hidden lg:flex")}/>}
                    <div className={cn(styles.companyDescriptionBlock__text)}>{props.bottomText}</div>
                    <div className={cn(styles.companyDescriptionBlock__text)}>{props.extraBottomText}</div>
                </div>
            </div>
            <img src={`${ApiBaseUrl}/api/media/${props.image}`} alt="" className={cn(styles.companyDescriptionBlock__img)}/>
        </div>
    )
}

export const CompanyDescriptionBlockInfo: TypedBlockTypeInfo<CompanyDescriptionBlockElement> = {
    id: "CompanyDescriptionBlock",
    name: "CompanyDescriptionBlock",
    preview: preview,
    renderer: CompanyDescriptionBlock,
    initialData: {
        title: "string",
        text: "",
        icon: null,
        bottomText: "string",
        extraBottomText: "",
        image: null
    },
    definition: {
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "icon",
                type: "Custom",
                customType: "Image",
                name: "Icon",
            },
            {
                id: "bottomText",
                type: "String",
                name: "Bottom Text",
            },
            {
                id: "extraBottomText",
                type: "String",
                name: "Extra Bottom Text",
            },
            {
                id: "image",
                type: "Custom",
                customType: "Image",
                name: "Image",
            },
        ]
    }
}
