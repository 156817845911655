import React, { useContext, useEffect, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import style from "./MoreAboutCompanyBlock.module.css";
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";

export interface MoreAboutCompanyBlockElement {
    header: string;
    bluetext: string;
    subtitle: string;
    elements: { title: string; text: string }[];
    textButton: string;
    linkButton: string;
    showButton: boolean;
}

const normalizeText = (text: string): string => {
    return text.toLowerCase().replace(/\s+/g, ' ').trim();
};

const highlightMatch = (header: string, bluetext: string): JSX.Element[] => {
    if (!bluetext) return [<span key="0">{header}</span>];

    const normalizedHeader = normalizeText(header);
    const normalizedBluetext = normalizeText(bluetext);

    const regex = new RegExp(`(${normalizedBluetext.split(' ').join('\\s*')})`, "gi");
    const parts = normalizedHeader.split(regex);

    let originalIndex = 0;

    return parts.map((part, index) => {
        const originalPart = header.substring(originalIndex, originalIndex + part.length);
        originalIndex += part.length;

        return regex.test(part) ? (
            <span key={index} style={{ backgroundColor: "rgba(206, 225, 243, 1)", color: "rgba(0, 48, 157, 1)" }}>
                {originalPart}
            </span>
        ) : (
            <span key={index}>{originalPart}</span>
        );
    });
};

const splitTitle = (title: string) => {
    const parts = title.split(' ');
    if (parts.length > 1) {
        return (
            <span>
                {parts[0]} <span className={style.moreAboutCompany__elementHeader}>{parts.slice(1).join(' ')}</span>
            </span>
        );
    }
    return <span>{title}</span>;
};

export const MoreAboutCompanyBlock = (props: MoreAboutCompanyBlockElement) => {
    const [isVisible, setIsVisible] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.5,
            }
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, []);

    return (
        <div className={cn(style.moreAboutCompany__body)} ref={blockRef}>
            <div className={cn("flex flex-col justify-center lg:items-start max-w-screen-xl w-full my-0 mx-auto px-4 lg:px-14", style.moreAboutCompany__animation, { [style.visible]: isVisible })} >
                <div className="flex items-center lg:items-start flex-col">
                    <div className={cn("pt-14", style.moreAboutCompany__header)}>
                        <h1>{highlightMatch(props.header, props.bluetext)}</h1>
                    </div>
                    <div className={cn(style.moreAboutCompany__subtitle, "w-11/12 md:w-4/5 lg:w-4/6")}>
                        {props.subtitle}
                    </div>
                    {props.showButton && (
                        <div className={cn(style.moreAboutCompany__buttonContainer)}>
                            <a className={cn(style.moreAboutCompany__button, "w-full")} href={props.linkButton}>
                                {props.textButton}
                            </a>
                        </div>
                    )}
                </div>
                <div className={cn(
                    style.moreAboutCompany__elements,
                    { [style.moreAboutCompany__elementsTwoColumns]: !props.showButton },
                    "hidden md:grid md:mt-5"
                )}>
                    {props.elements.map((el, index) => (
                        <div key={index} className={style.moreAboutCompany__element}>
                            <div className={style.moreAboutCompany__elementTitle}>
                                {splitTitle(el.title)}
                            </div>
                            <div className={style.moreAboutCompany__elementText}>
                                {el.text}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={cn("flex my-8 text-center md:hidden")}>
                    <Slider>
                        {props.elements.map((el, index) => (
                            <div key={index} className={style.slider__element}>
                                <div className={style.moreAboutCompany__elementTitle}>
                                    {splitTitle(el.title)}
                                </div>
                                <div className={style.moreAboutCompany__elementText}>
                                    {el.text}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export const MoreAboutCompanyBlockInfo: TypedBlockTypeInfo<MoreAboutCompanyBlockElement> = {
    id: "moreAboutCompanyBlock",
    name: "moreAboutCompanyBlock",
    preview: preview,
    renderer: MoreAboutCompanyBlock,
    initialData: {
        header: "Header",
        bluetext: "",
        subtitle: "",
        elements: [
            {
                title: "string",
                text: "string",
            },
        ],
        showButton: true,
        textButton: "search",
        linkButton: "",
    },
    definition: {
        subTypes: {
            element: {
                fields: [
                    {
                        id: "title",
                        type: "String",
                        name: "Title"
                    },
                    {
                        id: "text",
                        type: "String",
                        name: "Text"
                    },
                ],
            },
        },
        fields: [
            {
                id: "header",
                type: "String",
                name: "Header",
            },
            {
                id: "bluetext",
                type: "String",
                name: "Bluetext"
            },
            {
                id: "subtitle",
                type: "String",
                name: "Subtitle",
            },
            {
                id: "elements",
                type: "List",
                listType: "element",
                name: "Elements"
            },
            {
                id: "textButton",
                type: "String",
                name: "Text button",
            },
            {
                id: "linkButton",
                type: "String",
                name: "Link Button"
            },
            {
                id: "showButton",
                type: "CheckBox",
                name: "Show button",
            },
        ]
    }
}